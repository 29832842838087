import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { AuthContext } from '../../auth/AuthContext';
import * as XLSX from 'xlsx';
import './ValidatePaymentsFoodLove.css';

const ValidatePaymentsFoodLove = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const [payments, setPayments] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [rejectionReason, setRejectionReason] = useState('');
    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);

    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

    const fetchPayments = async () => {
        try {
            const response = await fetch(`${backendUrl}/api/payments_foodlove`);
            if (!response.ok) {
                throw new Error('Error al obtener los pagos');
            }
            const data = await response.json();
    
            // Actualiza el estado de los pagos en función de `rejection_reason`
            const updatedData = data.map(payment => ({
                ...payment,
                state: payment.rejection_reason ? 'Rechazado' : payment.status ? 'Validado' : 'Pendiente',
            }));
    
            setPayments(updatedData);
            setFilteredPayments(updatedData);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user?.role !== 'admin' && user?.role !== 'superadmin') {
            navigate('/home-foodlove'); // Redirige si el usuario no es admin
        } else {
            fetchPayments();
        }
    }, [user, navigate]);

    const formatDateTime = (dateTime) => {
        try {
            const date = new Date(dateTime);
            return date.toLocaleString();
        } catch (error) {
            return "Fecha Inválida";
        }
    };

    const handleValidatePayment = async (paymentId) => {
        if (!paymentId || isNaN(paymentId)) {
            alert('ID de pago inválido.');
            return;
        }
    
        try {
            const response = await fetch(`${backendUrl}/api/validate_payment_foodlove/${paymentId}`, {
                method: 'PATCH',
            });
            if (response.ok) {
                alert('Pago validado exitosamente.');
                fetchPayments(); // Actualiza la lista de pagos después de la validación
            } else {
                const errorData = await response.json();
                alert(`Error al validar el pago: ${errorData.message}`);
            }
        } catch (error) {
            alert('Hubo un problema al validar el pago. Inténtelo nuevamente más tarde.');
        }
    };

    const handleRejectPayment = (payment) => {
        setSelectedPayment(payment);
        setIsRejectionModalOpen(true);
    };

    const submitRejection = async () => {
        if (!selectedPayment || !rejectionReason) {
            alert('Debe ingresar un motivo para el rechazo.');
            return;
        }
    
        try {
            const response = await fetch(`${backendUrl}/api/reject_payment_foodlove/${selectedPayment.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ reason: rejectionReason }),
            });
    
            if (response.ok) {
                alert('Pago rechazado exitosamente.');
                setIsRejectionModalOpen(false);
                setRejectionReason('');
                // Actualiza el estado del pago directamente en el frontend
                const updatedPayments = payments.map(payment => 
                    payment.id === selectedPayment.id 
                    ? { ...payment, state: 'Rechazado', rejection_reason: rejectionReason }
                    : payment
                );
                setPayments(updatedPayments);
                setFilteredPayments(updatedPayments);
            } else {
                const errorData = await response.json();
                alert(`Error al rechazar el pago: ${errorData.message}`);
            }
        } catch (error) {
            alert('Hubo un problema al rechazar el pago. Inténtelo nuevamente más tarde.');
        }
    };

    const handleViewTransferProof = (payment) => {
        setSelectedPayment(payment);
        setModalIsOpen(true);
    };

    const renderTransferProof = () => {
        if (selectedPayment.transfer_image_url.endsWith('.pdf')) {
            return (
                <iframe 
                    src={selectedPayment.transfer_image_url} 
                    title="Comprobante de Transferencia"
                    frameBorder="0"
                    style={{ width: '100%', height: '500px' }}
                ></iframe>
            );
        } else {
            return (
                <img 
                    src={selectedPayment.transfer_image_url} 
                    alt="Comprobante de Transferencia"
                />
            );
        }
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setSelectedPayment(null);
    };

    const handleCloseRejectionModal = () => {
        setIsRejectionModalOpen(false);
        setSelectedPayment(null);
        setRejectionReason('');
    };

    const handleReturnToMenu = () => {
        navigate('/home-foodlove');
    };

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = payments.filter(payment =>
            payment.username.toLowerCase().includes(term) ||
            payment.transfer_number.toLowerCase().includes(term) ||
            payment.plan_price.toString().includes(term) ||
            payment.plan_description.toLowerCase().includes(term) ||
            payment.state.toLowerCase().includes(term)
        );
        setFilteredPayments(filtered);
    };

    const handleDownloadExcel = () => {
        const data = filteredPayments.map(payment => ({
            Usuario: payment.username,
            Plan: payment.plan_price,
            'Descripción del Plan': payment.plan_description,
            'Número de Transferencia': payment.transfer_number,
            'Fecha y Hora de Registro': formatDateTime(payment.created_at),
            Estado: payment.state,
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Pagos");
        XLSX.writeFile(workbook, "pagos_pendientes_validacion.xlsx");
    };

    if (loading) return <div>Cargando pagos pendientes...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="validate-payments">
            <h1>Pagos Pendientes de Validación</h1>
            <input 
                type="text" 
                placeholder="Buscar por usuario, número de transferencia, plan, descripción o estado" 
                value={searchTerm}
                onChange={handleSearch}
                className="search-input"
            />
            {filteredPayments.length === 0 ? (
                <p>No hay pagos pendientes de validación.</p>
            ) : (
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Usuario</th>
                                <th>Plan</th>
                                <th>Descripción del Plan</th>
                                <th>Número de Transferencia</th>
                                <th>Fecha y Hora de Registro</th>
                                <th>Estado</th>
                                <th>Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPayments.map((payment, index) => (
                                <tr key={payment.id || index}>
                                    <td>{payment.username}</td>
                                    <td>{payment.plan_price}</td>
                                    <td>{payment.plan_description}</td>
                                    <td>{payment.transfer_number}</td>
                                    <td>{formatDateTime(payment.created_at)}</td>
                                    <td>{payment.state}</td>
                                    <td className="action-buttons">
                                        <button className="view-proof-button" onClick={() => handleViewTransferProof(payment)}>
                                            Ver Comprobante
                                        </button>
                                        {!payment.status && !payment.rejection_reason && (
                                            <>
                                                <button className="validate-button" onClick={() => handleValidatePayment(payment.id)}>
                                                    Validar
                                                </button>
                                                <button className="reject-button" onClick={() => handleRejectPayment(payment)}>
                                                    Rechazar
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="return-button" onClick={handleReturnToMenu}>Regresar al Menú</button>
                    <button className="download-excel-button" onClick={handleDownloadExcel}>Descargar en Excel</button>
                </div>
            )}

            {selectedPayment && (
                <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
                    <div className="modal-content">
                        <h2 className="modal-header">Comprobante de Transferencia</h2>
                        <div className="transfer-proof">
                            {renderTransferProof()}
                        </div>
                        <button onClick={handleCloseModal} className="validate-button">Cerrar</button>
                    </div>
                </Modal>
            )}

            {isRejectionModalOpen && (
                <Modal isOpen={isRejectionModalOpen} onRequestClose={handleCloseRejectionModal}>
                    <div className="modal-content">
                        <h2 className="modal-header">Motivo de Rechazo</h2>
                        <textarea
                            value={rejectionReason}
                            onChange={(e) => setRejectionReason(e.target.value)}
                            placeholder="Ingrese el motivo del rechazo"
                            rows="5"
                            style={{ width: '100%' }}
                        ></textarea>
                        <button onClick={submitRejection} className="reject-button">Rechazar Pago</button>
                        <button onClick={handleCloseRejectionModal} className="close-button">Cerrar</button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default ValidatePaymentsFoodLove;