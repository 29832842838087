import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import QRCode from 'qrcode';
import { jsPDF } from 'jspdf';

const UploadComponentEddys = () => {
  const [uploadStatus, setUploadStatus] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.type === "application/pdf") {
      const formData = new FormData();
      formData.append('menu', file);

      setUploadStatus('Subiendo archivo...');

      axios.post(`${backendUrl}/upload_menu_Eddys`, formData)
        .then(response => {
          setUploadStatus('Archivo subido con éxito.');
          generateQRCode();
        })
        .catch(error => {
          console.error('Error al subir el archivo:', error);
          setUploadStatus('Error al subir el archivo.');
        });
    } else {
      alert("Por favor, carga un archivo PDF.");
      setUploadStatus('');
    }
  }, [backendUrl]);

  const generateQRCode = async () => {
    try {
      const qr = await QRCode.toDataURL('https://siemprecondescuentos.com/Eddys_Menu');
      setQrCodeUrl(qr);
    } catch (err) {
      console.error('Error generating QR Code', err);
      setUploadStatus('Error generando el código QR.');
    }
  };

  const downloadQRAsPDF = () => {
    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(qrCodeUrl);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(qrCodeUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('eddys-menu-qr.pdf');
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    multiple: false
  });

  return (
    <div>
      <Link to="/home-eddys" className="back-to-menu">Regresar al Menú</Link>
      <div {...getRootProps()} style={{ border: '2px dashed black', padding: '20px', textAlign: 'center', marginTop: '100px' }}>
        <input {...getInputProps()} />
        <p>Arrastra y suelta aquí tu archivo PDF, o haz clic para seleccionarlo.</p>
      </div>
      {uploadStatus && (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <p>{uploadStatus}</p>
          {qrCodeUrl && (
            <>
              <img src={qrCodeUrl} alt="QR Code" />
              <button onClick={downloadQRAsPDF}>Descargar QR del Menu digital</button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadComponentEddys;