import React, { useEffect, useState } from 'react';

/* global PaymentCheckout */

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

const PaymentOption = () => {
  const [isSdkReady, setIsSdkReady] = useState(false);

  useEffect(() => {
    // Load jQuery and the Payment Checkout SDK
    const loadScript = (src, id) => {
      return new Promise((resolve, reject) => {
        if (!document.getElementById(id)) {
          const script = document.createElement('script');
          script.src = src;
          script.id = id;
          script.onload = () => resolve(script);
          script.onerror = () => reject(new Error(`Error loading script ${src}`));
          document.head.appendChild(script);
        } else {
          resolve(document.getElementById(id));
        }
      });
    };

    loadScript('https://code.jquery.com/jquery-3.5.0.min.js', 'jquery-script')
      .then(() => loadScript('https://cdn.paymentez.com/ccapi/sdk/payment_checkout_3.0.0.min.js', 'paymentez-script'))
      .then(() => setIsSdkReady(true))
      .catch(err => console.error('Error loading scripts:', err));
  }, []);

  const handlePayment = async () => {
    if (!isSdkReady) {
      console.log('SDK is not ready');
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/init-reference`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();

      if (data.reference && typeof PaymentCheckout !== 'undefined') {
        const paymentCheckout = new PaymentCheckout.modal({
          env_mode: 'stg',
          onOpen: () => console.log('modal open'),
          onClose: () => console.log('modal closed'),
          onResponse: (response) => {
            console.log('modal response', response);
            document.getElementById('response').innerHTML = JSON.stringify(response);
          },
        });

        paymentCheckout.open({ reference: data.reference });
      }
    } catch (error) {
      console.error('Error obtaining the reference from backend:', error);
    }
  };

  return (
    <div>
      <button onClick={handlePayment} disabled={!isSdkReady}>Pay</button>
      <div id="response"></div>
    </div>
  );
};

export default PaymentOption;
