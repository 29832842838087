import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import logo from './logo-empanadasdepaco.png';
import whatsappIcon from './whatsapp-icon.png';  // Asegúrate de que la ruta sea correcta

const LoginEmpanadas = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { client } = useParams();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');

    const response = await login(email, password, client);

    if (response.success && response.user) {
      navigate(`/home-empanadas`);
    } else {
      setLoginError(response.message || 'Error desconocido al iniciar sesión. Intente de nuevo.');
    }
  };

  const handleRegister = () => {
    navigate(`/register-empanadas`);
  };

  const handleWhatsAppRedirect = () => {
    window.location.href = 'https://wa.me/593999442670';
  };

  const handleForgotPassword = () => {
    navigate(`/forgot-password/${client}`);
  };

  const handleCloseLoginForm = () => {
    setShowLoginForm(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
      {/* Header con logo y botones */}
      <header className="flex flex-col items-center space-y-6 bg-white py-8 shadow-lg rounded-lg w-full max-w-lg px-4 sm:px-6 lg:px-8">
        <img src={logo} alt="Empanadas del Paco Logo" className="w-28 h-28 object-contain" />
        <div className="flex flex-col sm:flex-row sm:space-x-4 w-full justify-center items-center space-y-4 sm:space-y-0">
          <button
            className="bg-red-600 text-white font-semibold py-2 px-4 rounded-lg w-full sm:w-auto"
            onClick={handleRegister}
          >
            Registrarse
          </button>
          <button
            className="bg-green-500 text-white font-semibold py-2 px-4 rounded-lg w-full sm:w-auto flex items-center justify-center space-x-2"
            onClick={handleWhatsAppRedirect}
          >
            <img
              src={whatsappIcon}
              alt="WhatsApp"
              className="h-5 w-5" // Ajuste del tamaño del ícono
            />
            <span>Contactar</span>
          </button>
          <button
            className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg w-full sm:w-auto"
            onClick={() => setShowLoginForm(!showLoginForm)}
          >
            Iniciar Sesión
          </button>
        </div>
      </header>

      {/* Formulario de inicio de sesión */}
      {showLoginForm && (
        <div className="mt-6 bg-white shadow-lg rounded-lg p-6 w-full max-w-md">
          <button
            className="text-gray-400 text-2xl font-bold absolute top-4 right-4"
            onClick={handleCloseLoginForm}
          >
            ×
          </button>
          <form onSubmit={handleLogin} className="space-y-6">
            <div className="form-group">
              <label htmlFor="email" className="block text-gray-700 font-medium">Correo electrónico</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="block text-gray-700 font-medium">Contraseña</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
            {loginError && <p className="text-red-500 text-sm">{loginError}</p>}
            <div className="flex justify-between items-center">
              <button type="submit" className="bg-red-600 text-white py-2 px-4 rounded-lg">
                Iniciar Sesión
              </button>
              <button
                type="button"
                className="text-blue-600 hover:underline"
                onClick={handleForgotPassword}
              >
                Olvidé mi contraseña
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Video promocional */}
      <div className="mt-10 w-full max-w-5xl px-4"> {/* Tamaño ajustado */}
        <div className="relative w-full overflow-hidden aspect-w-16 aspect-h-9">
          <iframe
            className="w-full h-full"
            src="https://www.youtube.com/embed/qn9kEFRkhCA"
            title="Empanadas del Paco Promo Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* Footer */}
      <footer className="mt-6 text-center text-gray-500 text-sm">
        <p>&copy; 2024 Empanadas del Paco. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default LoginEmpanadas;
