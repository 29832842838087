import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CreateClient.css';

const CreateClient = () => {
  const [marcas, setMarcas] = useState([]);
  const [selectedMarca, setSelectedMarca] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    const fetchMarcas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/marcas`);
        setMarcas(response.data);
      } catch (error) {
        console.error('Error al obtener las marcas:', error);
      }
    };

    fetchMarcas();
  }, []);

  const handleCreateClient = async () => {
    if (!selectedMarca) {
      setStatus('Selecciona una marca primero');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-client`, { brand_name: selectedMarca });
      setStatus(response.data.message);
    } catch (error) {
      console.error('Error al crear el cliente:', error);
      setStatus('Error al crear el cliente');
    }
  };

  return (
    <div className="create-client">
      <h1>Crear Nuevo Cliente</h1>
      <select value={selectedMarca} onChange={(e) => setSelectedMarca(e.target.value)}>
        <option value="">Selecciona una marca</option>
        {marcas.map((marca) => (
          <option key={marca.brand_name} value={marca.brand_name}>
            {marca.brand_name}
          </option>
        ))}
      </select>
      <button onClick={handleCreateClient}>Crear Cliente</button>
      {status && <p>{status}</p>}
    </div>
  );
};

export default CreateClient;
