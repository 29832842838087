import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [view, setView] = useState('emailForm');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { client } = useParams();

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/forgot-password/${client}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    if (response.ok) {
      setMessage('Revisa en tu email el código de reseteo de tu contraseña.');
      setView('resetForm');
    } else {
      setMessage(data.message);
    }
  };

  const handleResetSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reset-password/${client}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, token, newPassword }),
    });
    const data = await response.json();
    if (response.ok) {
      setMessage('Tu contraseña ha sido restablecida exitosamente. Redirigiendo al inicio de sesión...');
      setTimeout(() => {
        navigate(`/login/${client}`);
      }, 3000);
    } else {
      setMessage(data.message);
    }
  };

  return (
    <div>
      {view === 'emailForm' && (
        <div>
          <h1>Recuperación de contraseña</h1>
          <form onSubmit={handleEmailSubmit}>
            <input
              type="email"
              placeholder="Ingresa tu correo"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Enviar solicitud</button>
          </form>
        </div>
      )}
      {view === 'resetForm' && (
        <div>
          <h1>Ingresa el código recibido en tu email</h1>
          <form onSubmit={handleResetSubmit}>
            <input
              type="text"
              placeholder="Ingresa código de recuperación recibido en tu email"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Ingresa tu nueva contraseña"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <button type="submit">Cambiar contraseña</button>
          </form>
        </div>
      )}
      <p>{message}</p>
    </div>
  );
};

export default ForgotPassword;
