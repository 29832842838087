import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import Modal from 'react-modal';
import ProgramarComidas from './ProgramarComidas';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import logoFoodLove from './foodlove.jpg';
import userIcon from './user-icon.png';
import meal70usd from './70usd.jpg';
import meal99usd from './99usd.jpg';
import meal140usd from './140usd.jpg';
import transferGif from './transfer.gif';
import accountImage from './account.jpg';

const meals = [
  { id: 1, name: "20 Almuerzos", price: "70", description: "20 almuerzos saludables", image: meal70usd },
  { id: 2, name: "20 Almuerzos, 20 Cenas, 20 Aromáticas", price: "99", description: "20 almuerzos, 20 cenas, 20 aromáticas", image: meal99usd },
  { id: 3, name: "40 Almuerzos", price: "140", description: "40 almuerzos saludables", image: meal140usd },
];

const HomeFoodLove = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [transferNumber, setTransferNumber] = useState('');
  const [transferFile, setTransferFile] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [isPaymentValidated, setIsPaymentValidated] = useState(false);
  const [adminMenuOpen, setAdminMenuOpen] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  const handleLogout = () => {
    logout();
    navigate('/login/foodlove');
  };

  const handleMealSelection = (meal) => {
    setSelectedMeal(meal);
    setModalIsOpen(true);
  };

  const handleTransferNumberChange = (e) => setTransferNumber(e.target.value);

  const handleTransferFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 0) {
      setTransferFile(file);
    } else {
      alert('Por favor, seleccione un archivo válido.');
    }
  };

  const handlePaymentSubmit = async () => {
    if (!transferNumber || !transferFile || !selectedMeal) {
      alert('Por favor, complete todos los campos.');
      return;
    }

    setIsPaymentProcessing(true);
    const formData = new FormData();
    formData.append('email', user?.email || '');
    formData.append('planPrice', selectedMeal.price);
    formData.append('planDescription', selectedMeal.description);
    formData.append('transferNumber', transferNumber);
    formData.append('transferFile', transferFile);

    try {
      const response = await fetch(`${backendUrl}/api/payments_foodlove`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setIsPaymentValidated(true);
      } else {
        const errorData = await response.json();
        alert(`Error al registrar el pago: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error al enviar el pago:', error);
      alert('Hubo un problema al registrar su pago. Inténtelo nuevamente más tarde.');
    } finally {
      setIsPaymentProcessing(false);
    }
  };

  const handleReturnToMenu = () => {
    setIsPaymentProcessing(false);
    setIsPaymentValidated(false);
    setModalIsOpen(false);
    navigate('/home-foodlove');
  };

  const handleAdminMenuToggle = () => {
    if (user?.role === 'admin' || user?.role === 'superadmin') {
      setAdminMenuOpen(!adminMenuOpen);
    } else {
      alert('No tienes permisos para acceder a esta sección.');
    }
  };

  const handlePaymentValidation = () => {
    navigate('/validate-payments-foodlove');
  };

  const handleBalanceManagement = () => {
    navigate('/saldos-foodlove');
  };

  const handleMenuSelection = () => {
    window.location.href = 'https://forms.gle/JAZLi5wMyKZxh5on8';
  };

  return (
    <div className="flex flex-col items-center min-h-screen p-4 bg-gray-100">
      <header className="flex justify-between items-center w-full p-4 bg-white shadow-md rounded-md">
        <img src={logoFoodLove} alt="Logo FoodLove" className="h-20 object-contain" />
        <div className="relative">
          <div className="flex items-center bg-orange-500 p-2 rounded-md">
            <img src={userIcon} alt="User Icon" className="w-10 h-10 rounded-full bg-gray-100 p-1 mr-2" />
            <div className="flex flex-col items-start">
              <span className="text-white text-lg">Bienvenido, {user?.name}</span>
              {(user?.role === 'admin' || user?.role === 'superadmin') && (
                <button className="text-white mt-2" onClick={handleAdminMenuToggle}>Admin Menu</button>
              )}
            </div>
          </div>
          {adminMenuOpen && (
            <ul className="absolute right-0 mt-2 w-48 bg-white text-black shadow-lg rounded-md z-50">
              <li className="p-2 hover:bg-gray-200 cursor-pointer" onClick={handlePaymentValidation}>Validar Pagos</li>
              <li className="p-2 hover:bg-gray-200 cursor-pointer" onClick={handleBalanceManagement}>Administración de Saldos</li>
            </ul>
          )}
        </div>
      </header>

      <main className="flex flex-col items-center justify-center flex-grow w-full max-w-4xl text-center mt-4">
        <h1 className="text-2xl text-gray-800 mb-4">Selecciona tu paquete de comidas</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full">
          {meals.map(meal => (
            <div key={meal.id} className="meal-item flex flex-col items-center bg-white p-4 rounded-md shadow-md hover:scale-105 transform transition-transform cursor-pointer" onClick={() => handleMealSelection(meal)}>
              <img src={meal.image} alt={meal.name} className="w-full h-32 object-cover rounded-md" />
              <p className="mt-4 text-lg font-semibold">{meal.name}</p>
              <p className="text-orange-500 font-bold text-lg">${meal.price}</p>
              <p className="text-gray-600">{meal.description}</p>
            </div>
          ))}
        </div>
      </main>

      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="flex justify-center items-center h-screen">
        <div className="relative bg-white p-8 rounded-md shadow-md w-full max-w-md">
          <button onClick={() => setModalIsOpen(false)} className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none">
            &times;
          </button>
          <h2 className="text-xl mb-4 text-center font-bold text-black">Pagar por transferencia</h2>
          <img src={accountImage} alt="Datos de la cuenta" className="mx-auto mb-4 w-2/3 h-auto" />
          <div className="form-group">
            <input type="text" className="form-control mb-4" placeholder="Número de transferencia" value={transferNumber} onChange={handleTransferNumberChange} />
            <input type="file" className="form-control mb-4" accept="image/*,.pdf,.doc,.docx" onChange={handleTransferFileChange} />
            <button className="btn btn-primary w-100" onClick={handlePaymentSubmit}>Enviar pago</button>
          </div>
        </div>
      </Modal>

      {isPaymentProcessing && !isPaymentValidated && (
        <div className="processing-modal fixed inset-0 flex justify-center items-center bg-white bg-opacity-75">
          <img src={transferGif} alt="Registrando pago" className="w-12 h-12 mb-4" />
          <p className="text-lg">Registrando su pago, por favor espere...</p>
        </div>
      )}

      {isPaymentValidated && (
        <div className="processing-modal fixed inset-0 flex justify-center items-center bg-white bg-opacity-75">
          <img src={transferGif} alt="Validando pago" className="w-12 h-12 mb-4" />
          <p className="text-lg">Validando su pago, se enviará un email de confirmación al validar.</p>
          <button className="btn btn-success mt-4" onClick={handleReturnToMenu}>Regresar al Menú</button>
        </div>
      )}

      <button className="btn btn-warning mt-6 w-40" onClick={handleMenuSelection}>
        Elige el menú de tu semana
      </button>

      <div className="w-full mt-6">
        <ProgramarComidas />
      </div>

      <button className="btn btn-danger mt-4 w-40" onClick={handleLogout}>Cerrar sesión</button>
    </div>
  );
};

export default HomeFoodLove;