// Import React from 'react'; // Assuming ES6 imports are used across your project
import React from 'react';

// HomeElManglar component
const HomeElManglar = () => {
  return (
    <div>
      <h1>Welcome to El Manglar!</h1>
      <p>Explore our unique features and offering77777s.</p>
    </div>
  );
};

export default HomeElManglar;
