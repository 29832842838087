// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
  }
  
  .auth h2 {
    margin-bottom: 20px;
    color: #343a40;
  }
  
  .auth input {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    border: 1px solid #ced4da;
    border-radius: 5px;
  }
  
  .auth button {
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .auth button:hover {
    background-color: #0056b3;
  }
  
  .auth p {
    color: red;
    margin-top: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/encuestadoraxy/LoginEncuestadoraXY.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB","sourcesContent":[".auth {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    min-height: 100vh;\r\n    background-color: #f8f9fa;\r\n    padding: 20px;\r\n  }\r\n  \r\n  .auth h2 {\r\n    margin-bottom: 20px;\r\n    color: #343a40;\r\n  }\r\n  \r\n  .auth input {\r\n    margin-bottom: 10px;\r\n    padding: 10px;\r\n    width: 100%;\r\n    max-width: 300px;\r\n    border: 1px solid #ced4da;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .auth button {\r\n    padding: 10px 20px;\r\n    background-color: #007bff;\r\n    border: none;\r\n    color: white;\r\n    cursor: pointer;\r\n    border-radius: 5px;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .auth button:hover {\r\n    background-color: #0056b3;\r\n  }\r\n  \r\n  .auth p {\r\n    color: red;\r\n    margin-top: 10px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
