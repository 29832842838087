import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import logo from './logo-lacostenita.png';
import './HomeLaCostenita.css';

const HomeLaCostenita = () => {
  const { user, logout } = useContext(AuthContext); // Obtener la función logout del contexto
  const navigate = useNavigate();
  const [showConfig, setShowConfig] = useState(false);
  const configRef = useRef(null);

  const handleNavigation = (path) => {
    navigate(path);
    setShowConfig(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login/la_costenita');
  };

  useEffect(() => {
    if (!user) {
      navigate('/login/la_costenita');
    } else if (user.client !== 'la_costenita') {
      navigate('/login/la_costenita');
    }
  }, [user, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (configRef.current && !configRef.current.contains(event.target)) {
        setShowConfig(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [configRef]);

  useEffect(() => {
    const handlePopState = () => {
      navigate('/login/la_costenita');
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  return (
    <div className="home-la-costenita">
      <div className="top-bar">
        <img src={logo} alt="Logo La Costeñita" className="logo" />
        <div className="top-right-container">
          <span className="user-greeting">Bienvenido, {user?.name}</span>
          {user && (user.role === 'superadmin' || user.role === 'admin') && (
            <button onClick={() => setShowConfig(!showConfig)} className="config-button">
              CONFIG
            </button>
          )}
          {showConfig && (
            <div className="config-dropdown" ref={configRef}>
              <button onClick={() => handleNavigation('/settings')}>Configuración de sistema</button>
              <button onClick={handleLogout}>Cerrar sesión</button>
              {(user.role === 'admin' || user.role === 'superadmin') && (
                <>
                  <button onClick={() => handleNavigation('/UploadComponentLaCostenita')}>Cargar menú</button>
                  {user.role === 'superadmin' && (
                    <button onClick={() => handleNavigation('/UploadQRCodeMenuLaCostenita')}>Cargar QR Menú</button>
                  )}
                  <button onClick={() => handleNavigation('/DownloadLatestQRMenuLaCostenita')}>Descargar QR nuevos registros</button>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {user && user.role === 'superadmin' && (
        <>
          <button onClick={() => handleNavigation('/ValidadorCliente-LaCostenita')}>Ir a Validación de nuevos socios</button>
          <button onClick={() => handleNavigation('/LaCostenita_Menu')}>Ver Menú</button>
        </>
      )}

      {user && user.role === 'admin' && user.client === 'la_costenita' && (
        <>
          <button onClick={() => handleNavigation('/ValidadorCliente-LaCostenita')}>Ir a Validación de nuevos socios</button>
          <button onClick={() => handleNavigation('/LaCostenita_Menu')}>Ver Menú</button>
        </>
      )}

      {user && user.role === 'employee' && user.client === 'la_costenita' && (
        <>
          <button onClick={() => handleNavigation('/ValidadorCliente-LaCostenita')}>Ir a Validación de nuevos socios</button>
          <button onClick={() => handleNavigation('/LaCostenita_Menu')}>Ver Menú</button>
        </>
      )}

      {user && user.role === 'client' && user.client === 'la_costenita' && (
        <>
          <button onClick={() => handleNavigation('/LaCostenita_Menu')}>Ver Menú</button>
        </>
      )}

      <button onClick={() => handleNavigation('/tarjeta-de-contacto-la-costenita')}>Quejas y sugerencias</button>
      <button onClick={handleLogout}>Cerrar sesión</button>
      <button onClick={() => handleNavigation('/Unsubscribe_lacostenita')}>Dejar de ser miembro</button>

      {!user && (
        <p>Descubre nuestros productos y disfruta de promociones exclusivas.</p>
      )}
    </div>
  );
};

export default HomeLaCostenita;
