import React, { useState, useContext } from 'react';
import axios from 'axios';
import logo from './logo-DeLena.png'; // Asegúrate de que la ruta del logo es correcta
import './UserDeLenaValidation.css'; // Asegúrate de que el archivo CSS está en la ubicación correcta
import { AuthContext } from '../../auth/AuthContext'; // Asegúrate de que la ruta del contexto de autenticación es correcta
import { Link } from 'react-router-dom'; // Importa el componente Link para el botón de regresar

const UserDeLenaValidation = () => {
  const { user } = useContext(AuthContext); // Obtén el usuario del contexto de autenticación
  const [clientCode, setClientCode] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState('');
  const [isUserValidated, setIsUserValidated] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  const handleValidateClient = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/users/validate_DeLena/${clientCode}`);
      // Comprobar si el usuario ya ha sido validado
      if (response.data.user_validated) {
        setIsUserValidated(true);
        setError('El usuario ya ha sido validado previamente.');
      } else {
        setUserInfo(response.data);
        setError('');
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError('Usuario no encontrado. Por favor, valide los datos.');
      } else {
        setError('Error al obtener la información del usuario.');
      }
      setUserInfo(null);
      setIsUserValidated(false); // Asegúrate de reiniciar el estado de validación
    }
  };

  const handleConfirmValidation = async () => {
    try {
      const response = await axios.patch(`${backendUrl}/api/users/validate_DeLena/${clientCode}`);
      setIsUserValidated(true);
      setUserInfo(response.data.user); // Actualiza la información del usuario
      setError(''); // Limpia cualquier error existente
    } catch (err) {
      if (err.response && err.response.status === 409) {
        setError('El usuario ya ha sido validado anteriormente.');
        setIsUserValidated(true); // Ajusta el estado como validado
      } else {
        setError('Error al validar el usuario.');
        setIsUserValidated(false); // Asegúrate de que el estado refleje que el usuario no está validado
      }
    }
  };

  // Verificar si el usuario cumple con las reglas de acceso
  const isAllowedAccess = () => {
    if (!user) return false; // Si no hay usuario autenticado, no se permite el acceso
    if (user.role === 'superadmin') return true; // El superadmin puede acceder sin restricciones

    // Los usuarios con rol 'admin' y 'employee' deben ser de 'la_costenita' para acceder
    if (user.role === 'admin' || user.role === 'employee') {
      return user.client === 'delena';
    }

    return false; // Por defecto, no se permite el acceso
  };

  // Renderizar el componente solo si el usuario tiene acceso
  if (!isAllowedAccess()) {
    return <p>Acceso restringido. Por favor, inicia sesión como administrador o empleado de Leña.</p>;
  }

  return (
    <div className="validation-container">
      <div className="header">
        <img src={logo} alt="La Costeñita" />
        <Link to="/home-delena" className="home-link">Regresar al menú</Link> {/* Botón para regresar al inicio */}
        <h2>Validación de Usuarios y Promociones</h2>
      </div>
      <input
        type="text"
        placeholder="Ingrese el código del cliente o la promo"
        value={clientCode}
        onChange={(e) => setClientCode(e.target.value)}
      />
      <button onClick={handleValidateClient}>Buscar Cliente</button>
      {error && <p className="error">{error}</p>}
      {userInfo && (
        <div className="user-info">
          <p>Nombre: {userInfo.name || 'No proporcionado'}</p>
          <p>Correo: {userInfo.email || 'No proporcionado'}</p>
          <p>Cédula: {userInfo.identification_id || 'No proporcionado'}</p>
          <p>Celular: {userInfo.phonenumber || 'No proporcionado'}</p>
          {!isUserValidated && (
            <button onClick={handleConfirmValidation}>Validar Cliente</button>
          )}
          {isUserValidated && <p>Cliente validado.</p>}
        </div>
      )}
    </div>
  );
};

export default UserDeLenaValidation;
