import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo-empanadasdepaco.png';
import './IAMarketing.css';
import { Bar, Line, Pie, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

const currentMonth = new Date().toLocaleString('es', { month: 'long' });

const IAMarketing = () => {
  // Ejemplo de datos para el Gráfico de Barras
  const data1 = {
    labels: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
    datasets: [
      {
        label: 'Alcance - Hombres',
        data: [12000, 19000, 3000, 5000, 2000, 3000],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Alcance - Mujeres',
        data: [13000, 15000, 4000, 6000, 2500, 3500],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      }
    ]
  };

  const options1 = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  // Ejemplo de datos para el Gráfico de Líneas
  const data2 = {
    labels: ['Anuncio 1', 'Anuncio 2', 'Anuncio 3', 'Anuncio 4'],
    datasets: [
      {
        label: 'Reducción del costo por resultado con IA',
        data: [2.5, 2.0, 1.5, 1.0], // Se va reduciendo con el tiempo
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  // Ejemplo de datos para el Gráfico Circular
  const data3 = {
    labels: ['Anuncio 1', 'Anuncio 2', 'Anuncio 3', 'Anuncio 4'],
    datasets: [
      {
        label: 'Tasa de Clics (CTR) con IA',
        data: [50, 60, 70, 30],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)'
        ],
      }
    ]
  };

  // Ejemplo de datos para el Gráfico de Rosca
  const data4 = {
    labels: ['Anuncio 1', 'Anuncio 2', 'Anuncio 3', 'Anuncio 4'],
    datasets: [
      {
        label: 'Costo por Clic (CPC) con IA',
        data: [1.2, 0.8, 0.5, 1.0],
        backgroundColor: [
          'rgba(153, 102, 255, 0.5)',
          'rgba(255, 159, 64, 0.5)',
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)'
        ],
      }
    ]
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div className="logo-header">
        {/* Haz clic en el logo para volver al menú principal */}
        <Link to="/">
          <img src={logo} alt="Logo Empanadas del Paco" className="logo" />
        </Link>
        {/* Botón de texto también para volver */}
        <Link to="/" className="back-to-menu">Regresar al Menú</Link>
      </div>
      <h2 style={{ marginBottom: '20px' }}>
        Leads generados con IA en {currentMonth} con descuentos directos a tu e-commerce
      </h2>
      <h1 style={{ fontSize: '48px', color: '#007bff', marginBottom: '20px' }}>373</h1>
      <h2 style={{ marginBottom: '20px' }}>Alcance de marca potenciado con IA %</h2>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        <div style={{ margin: '20px', width: '400px' }}>
          <h3 style={{ marginBottom: '10px' }}>
            Alcance demográfico segmentado por género
          </h3>
          <Bar data={data1} options={options1} />
        </div>
        <div style={{ margin: '20px', width: '400px' }}>
          <h3 style={{ marginBottom: '10px' }}>
            Tendencia de reducción del costo por resultado con IA
          </h3>
          <Line data={data2} />
        </div>
        <div style={{ margin: '20px', width: '400px' }}>
          <h3 style={{ marginBottom: '10px' }}>
            Tasa de Clics (CTR) en anuncios con IA
          </h3>
          <Pie data={data3} />
        </div>
        <div style={{ margin: '20px', width: '400px' }}>
          <h3 style={{ marginBottom: '10px' }}>
            Costo por Clic (CPC) en anuncios con IA
          </h3>
          <Doughnut data={data4} />
        </div>
      </div>
    </div>
  );
};

export default IAMarketing;