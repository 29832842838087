import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EditSurveyModal = ({
  survey,
  setSurvey,
  handleInputChange,
  handleDateChange,
  handleAddOption,
  handleUpdateOption,
  handleAddQuestion,
  handleUpdateQuestion,
  handleSubmit,
  closeModal
}) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={closeModal}>&times;</span>
        <h2>Editar Encuesta</h2>
        <div className="input-group">
          <label htmlFor="title">Título de la encuesta</label>
          <input
            type="text"
            name="title"
            value={survey.title}
            onChange={handleInputChange}
            placeholder="Título de la encuesta"
          />
        </div>
        <div className="input-group">
          <label htmlFor="duration">Duración de la encuesta</label>
          <DatePicker
            selectsRange
            startDate={survey.duration.start}
            endDate={survey.duration.end}
            onChange={handleDateChange}
            isClearable={true}
          />
        </div>
        <div className="input-group">
          <label htmlFor="questionText">Texto de la pregunta</label>
          <input
            type="text"
            name="questionText"
            value={survey.questionText}
            onChange={e => setSurvey({ ...survey, questionText: e.target.value })}
            placeholder="Texto de la pregunta"
          />
        </div>
        <div className="input-group">
          <label htmlFor="questionType">Tipo de pregunta</label>
          <select name="questionType" value={survey.questionType} onChange={e => setSurvey({ ...survey, questionType: e.target.value })}>
            <option value="multiple_choice">Respuesta múltiple</option>
            <option value="open_ended">Pregunta abierta</option>
          </select>
        </div>
        <button className="button" onClick={handleAddQuestion}>Agregar Pregunta</button>
        <div>
          {survey.questions.map((q, index) => (
            <div key={index} className="question-item">
              <input
                type="text"
                value={q.question_text || q.texto}
                onChange={e => handleUpdateQuestion(index, e.target.value)}
                placeholder="Texto de la pregunta"
              />
              {q.question_type === 'multiple_choice' && (
                <div>
                  {q.opciones.map((opcion, idx) => (
                    <div key={idx} className="input-group">
                      <input
                        type="text"
                        value={opcion}
                        onChange={e => handleUpdateOption(index, idx, e.target.value)}
                        placeholder="Opción de respuesta"
                      />
                    </div>
                  ))}
                  <div className="input-group">
                    <input
                      type="text"
                      value={survey.optionText}
                      onChange={e => setSurvey({ ...survey, optionText: e.target.value })}
                      placeholder="Nueva opción"
                    />
                    <button className="button" onClick={() => handleAddOption(index)}>Agregar Opción</button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <button className="button" onClick={handleSubmit}>Actualizar Encuesta</button>
      </div>
    </div>
  );
};

export default EditSurveyModal;
