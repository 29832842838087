import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const DeLena_Menu = () => {
  const [pdfFileUrl, setPdfFileUrl] = useState('');
  const navigate = useNavigate(); // Hook para la navegación
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  useEffect(() => {
    const fetchLatestMenuUrl = async () => {
      try {
        const response = await axios.get(`${backendUrl}/latest-menu-url-DeLena`);
        setPdfFileUrl(response.data.pdfUrl); // Asumiendo que el backend envía un objeto con la propiedad 'pdfUrl'
      } catch (error) {
        console.error('Error fetching the latest menu URL:', error);
      }
    };

    fetchLatestMenuUrl();
  }, [backendUrl]);

  if (!pdfFileUrl) {
    return <div>Cargando menu...</div>;
  }

  // Función para volver al menú principal
  const goBack = () => {
    navigate(-1); // O puedes usar navigate('/') para ir directamente a una ruta específica
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <button onClick={goBack} style={{ margin: '10px', position: 'absolute', zIndex: 1000 }}>Volver al Menú</button>
      <iframe
        src={pdfFileUrl}
        title="Empanadas Del Paco Menu"
        frameBorder="0"
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default DeLena_Menu;
