import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import logoEddysBBQ from './logo-eddysbbq.png';
import fastFoodLogo from './fast-food-logo.png';
import drinksLogo from './drinks-logo.png';
import dessertsLogo from './desserts-logo.png';
import userIcon from './user-icon.png';
import Modal from 'react-modal';

const HomeEddysBBQ = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showConfig, setShowConfig] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const configRef = useRef(null);

  const handleNavigation = (path) => {
    navigate(path);
    setShowConfig(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login/eddys_bbq');
  };

  useEffect(() => {
    if (!user) {
      navigate('/login/eddys_bbq');
    } else if (user.client !== 'eddys_bbq') {
      navigate('/login/eddys_bbq');
    }
  }, [user, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (configRef.current && !configRef.current.contains(event.target)) {
        setShowConfig(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [configRef]);

  useEffect(() => {
    const handlePopState = () => {
      navigate('/login/eddys_bbq');
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const menuOptions = [
    {
      name: 'Fast Food',
      logo: fastFoodLogo,
      functionalities: [
        { name: 'Ver el Menú', path: '/ver-menu-eddysbbq' },
        { name: 'Cargar menú', path: '/UploadComponentEddysBBQ' },
        { name: 'Descargar QR nuevos registros', path: '/DownloadLatestQRMenuEddysBBQ' },
        { name: 'Quejas y sugerencias', path: '/tarjeta-de-contacto-eddysbbq' }
      ]
    },
    {
      name: 'Drinks',
      logo: drinksLogo,
      functionalities: [
        { name: 'IA', path: '/drinks/optionA' },
        { name: 'MKT', path: '/drinks/optionB' },
        { name: 'INTEGRACION RESTAURANTES', path: '/drinks/optionC' }
      ]
    },
    {
      name: 'Desserts',
      logo: dessertsLogo,
      functionalities: [
        { name: 'BUSINESS INTELLIGENCE', path: '/desserts/optionX' },
        { name: 'BLOG', path: '/desserts/optionY' },
        { name: 'RASPADITAS', path: '/desserts/optionZ' }
      ]
    }
  ];

  const handleOptionClick = (option) => {
    if (option.name === 'Drinks' || option.name === 'Desserts') {
      setIsPopupOpen(true);
    } else {
      setSelectedOption(option.name);
    }
  };

  const selectedMenuOption = menuOptions.find(option => option.name === selectedOption);

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="max-w-6xl mx-auto">
        <header className="bg-white shadow-md rounded-lg p-4 mb-6 flex justify-between items-center">
          <img src={logoEddysBBQ} alt="Logo Eddy's BBQ" className="h-16 object-contain" />
          <div className="relative">
            <div className="flex items-center bg-orange-500 p-2 rounded-md">
              <img src={userIcon} alt="User Icon" className="w-10 h-10 rounded-full bg-white p-1 mr-2" />
              <div>
                <span className="text-white text-sm">Bienvenido, {user?.name}</span>
                {user && (user.role === 'superadmin' || user.role === 'admin') && (
                  <button onClick={() => setShowConfig(!showConfig)} className="block mt-1 text-white text-xs bg-orange-600 px-2 py-1 rounded">
                    CONFIG
                  </button>
                )}
              </div>
            </div>
            {showConfig && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10" ref={configRef}>
                {user.role === 'superadmin' && (
                  <button onClick={() => handleNavigation('/settings')} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Configuración de sistema
                  </button>
                )}
                <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  Cerrar sesión
                </button>
              </div>
            )}
          </div>
        </header>

        <main className="bg-white shadow-md rounded-lg p-6 mb-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {menuOptions.map(option => (
              <div key={option.name} className="bg-gray-50 p-4 rounded-lg shadow hover:shadow-md transition-shadow cursor-pointer" onClick={() => handleOptionClick(option)}>
                <img src={option.logo} alt={`${option.name} logo`} className="w-16 h-16 mx-auto mb-2" />
                <p className="text-center font-semibold">{option.name}</p>
              </div>
            ))}
          </div>
          {selectedMenuOption && (
            <div className="mt-6">
              <h2 className="text-xl font-bold mb-4">{selectedMenuOption.name} Functionalities</h2>
              <ul className="space-y-2">
                {selectedMenuOption.functionalities.map((func, index) => (
                  <li key={index} className="bg-gray-50 p-2 rounded hover:bg-gray-100 cursor-pointer" onClick={() => handleNavigation(func.path)}>
                    {func.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </main>

        <footer className="flex flex-col space-y-2">
          <button className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600 transition-colors" onClick={handleLogout}>
            Cerrar sesión
          </button>
          <button className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors" onClick={() => handleNavigation('/Unsubscribe_EddysBBQ')}>
            Dejar de ser miembro
          </button>
        </footer>

        {!user && (
          <p className="mt-4 text-center text-gray-600">Descubre nuestros productos y disfruta de promociones exclusivas.</p>
        )}
      </div>

      <Modal
        isOpen={isPopupOpen}
        onRequestClose={() => setIsPopupOpen(false)}
        contentLabel="Contact Sales Modal"
        className="bg-white rounded-lg shadow-xl p-6 max-w-md mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start overflow-y-auto"
      >
        <h2 className="text-2xl font-bold mb-4">Contacta con el Área de Ventas</h2>
        <p className="mb-6">Para ver las funcionalidades de Drinks y Desserts, por favor comunícate con el área de ventas para poder agregarlos a tu plan.</p>
        <button className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600 transition-colors" onClick={() => setIsPopupOpen(false)}>
          Cerrar
        </button>
      </Modal>
    </div>
  );
};

export default HomeEddysBBQ;