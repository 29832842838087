// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-client {
    text-align: center;
  }
  
  .create-client select {
    margin: 10px;
    padding: 10px;
  }
  
  .create-client button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .create-client button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CreateClient.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".create-client {\r\n    text-align: center;\r\n  }\r\n  \r\n  .create-client select {\r\n    margin: 10px;\r\n    padding: 10px;\r\n  }\r\n  \r\n  .create-client button {\r\n    padding: 10px 20px;\r\n    background-color: #007bff;\r\n    color: white;\r\n    border: none;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .create-client button:hover {\r\n    background-color: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
