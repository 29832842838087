import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../auth/AuthContext';
import Swal from 'sweetalert2';
import './HomeSuperAdmin.css'; // Asegúrate de que la ruta al archivo CSS es correcta

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

const HomeSuperAdmin = () => {
  const { user } = useContext(AuthContext);
  const [newClient, setNewClient] = useState({
    name: '',
    logo: null,
    modules: {
      surveys: false,
      complaints: false,
      coupons: false,
      onlineMenu: false,
      clientValidation: false
    }
  });
  const [message, setMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'checkbox') {
      setNewClient(prevState => ({
        ...prevState,
        modules: {
          ...prevState.modules,
          [name]: checked
        }
      }));
    } else if (type === 'file') {
      setNewClient(prevState => ({
        ...prevState,
        [name]: files[0]
      }));
    } else {
      setNewClient(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newClient.name.trim() || !newClient.logo) {
      setMessage('Nombre y logo son requeridos.');
      return;
    }

    const formData = new FormData();
    formData.append('name', newClient.name);
    formData.append('logo', newClient.logo);
    formData.append('modules', JSON.stringify(newClient.modules));

    try {
      const response = await axios.post(`${backendUrl}/api/create-client`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setMessage('Cliente creado con éxito. Los detalles seguirán.');
      Swal.fire({
        title: '¡Éxito!',
        text: `Registro exitoso de nuevo cliente: ${response.data.client.name}`,
        icon: 'success',
        confirmButtonText: 'Ok'
      });
      setNewClient({ name: '', logo: null, modules: { surveys: false, complaints: false, coupons: false, onlineMenu: false, clientValidation: false } });
    } catch (error) {
      setMessage(`Error: ${error.response?.data.message || error.message}`);
      Swal.fire({
        title: 'Error',
        text: `No se pudo registrar el cliente: ${error.response?.data.message || error.message}`,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };

  if (!user || user.role !== 'superadmin') {
    return <p>Access denied. Only for superadmins.</p>;
  }

  return (
    <div className="admin-panel">
      <h1>Administración Siempre Con Descuentos</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <label>
          Nombre del Cliente:
          <input type="text" name="name" value={newClient.name} onChange={handleInputChange} required />
        </label>
        <label>
          Logo del Cliente (Formato PNG, fondo transparente):
          <input type="file" name="logo" onChange={handleInputChange} required />
        </label>
        <fieldset>
          <legend>Módulos:</legend>
          <label>
            Encuestas
            <input type="checkbox" name="surveys" checked={newClient.modules.surveys} onChange={handleInputChange} />
          </label>
          <label>
            Quejas y sugerencias
            <input type="checkbox" name="complaints" checked={newClient.modules.complaints} onChange={handleInputChange} />
          </label>
          <label>
            Cupones
            <input type="checkbox" name="coupons" checked={newClient.modules.coupons} onChange={handleInputChange} />
          </label>
          <label>
            Menú en línea
            <input type="checkbox" name="onlineMenu" checked={newClient.modules.onlineMenu} onChange={handleInputChange} />
          </label>
          <label>
            Validación de clientes
            <input type="checkbox" name="clientValidation" checked={newClient.modules.clientValidation} onChange={handleInputChange} />
          </label>
        </fieldset>
        <button type="submit">Crear Cliente</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default HomeSuperAdmin;
