// TarjetaDeContacto.js
import React, { useContext } from 'react';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import whatsappIcon from './whatsapp-icon.png'; // Verifica la ruta al icono de WhatsApp
import logo from './logo-DeLena.png';
import './TarjetaDeContactoDeLena.css';

const TarjetaDeContactoCostenita = () => {
  const { user } = useContext(AuthContext);
  const phone = '+593983119817'; // Este es el número de teléfono para WhatsApp

  const whatsappMessage = `Hola, soy ${user ? user.name : 'un miembro del club de La Costeñita'}, me gustaría comentar que...`;
  const whatsappUrl = `https://wa.me/${phone}?text=${encodeURIComponent(whatsappMessage)}`;

  const vCardString = `BEGIN:VCARD\nVERSION:3.0\nN:${user ? user.lastname : ''};${user ? user.firstname : ''};;;\nFN:${user ? `${user.firstname} ${user.lastname}` : 'Nombre Apellido'}\nEMAIL:${user ? user.email : 'correo@example.com'}\nTEL;TYPE=CELL:${phone}\nEND:VCARD`;
  const blob = new Blob([vCardString], { type: 'text/vcard' });
  const vCardUrl = URL.createObjectURL(blob);

  const downloadVCard = () => {
    const link = document.createElement('a');
    link.href = vCardUrl;
    link.download = 'contact.vcf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="card-container">
      <Link to="/home-delena" className="card-back-link">Regresar al Menú</Link>
      <Link to="/home-delena" className="logo-link">
        <img src={logo} alt="Logo Empanadas del Paco" className="card-logo" />
      </Link>
      <h1 className="card-title">Quejas y sugerencias directas🫠😟😖</h1>
      <button className="card-download-btn" onClick={downloadVCard}>
        Descargar Contacto
      </button>
      <QRCode value={whatsappUrl} size={256} className="card-qr" />
      <a href={whatsappUrl} className="card-whatsapp-link" target="_blank" rel="noopener noreferrer">
        <img src={whatsappIcon} alt="WhatsApp" style={{ maxWidth: '30px', marginRight: '10px' }} />
        Iniciar conversación en WhatsApp
      </a>
    </div>
  );
};

export default TarjetaDeContactoCostenita;

