import React, { useState, useRef, useEffect } from 'react';
import './Raspaditas.css';
import { Link } from 'react-router-dom'; 
import empanadaImage from '../../assets/empanada.png'; // Importa la imagen de la empanada
import logo from './logo-empanadasdepaco.png';

const Raspaditas = () => {
  const [prize, setPrize] = useState('');
  const [isDrawing, setIsDrawing] = useState(false);
  const [showText, setShowText] = useState(true); // Estado para controlar la visibilidad del texto
  const canvasRef = useRef(null);

  // Handle the scratch effect
  const handleMouseDown = () => {
    setIsDrawing(true);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const bounds = canvas.getBoundingClientRect();
    const x = e.clientX - bounds.left;
    const y = e.clientY - bounds.top;

    // Increase the transparency of the canvas at the mouse position
    ctx.clearRect(x - 10, y - 10, 20, 20);

    if (!prize) {
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData.data;

      let pixelCount = 0;
      for (let i = 0; i < pixels.length; i += 4) {
        if (pixels[i + 3] === 0) {
          pixelCount++;
        }
      }
 
      const percentageTransparent = (pixelCount / (canvas.width * canvas.height)) * 100;
      if (percentageTransparent > 17) {
        setShowText(false); // Ocultar el texto cuando se revela el premio
        setPrize(null); // Limpiar el mensaje de premio
        // Mostrar la imagen de la empanada
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Limpiar el lienzo
        const empanadaImg = new Image();
        empanadaImg.src = empanadaImage; // Ruta de la imagen de la empanada
        empanadaImg.onload = () => {
          ctx.drawImage(empanadaImg, 0, 0, canvas.width, canvas.height);
        };
      }
    }
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  useEffect(() => {
    // Initialize the canvas with an overlay color
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#cccccc'; // Color gris
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Rellenar con color gris
  }, []);

  return (
    <div className="scratch-container">
      <div className="logo-header">
      <img src={logo} alt="Logo Empanadas del Paco" className="logo" />
      <Link to="/" className="back-to-menu">Regresar al Menú</Link>
      </div>
      {showText && <p className="instructions-text">¡Raspa para revelar tu premio!</p>}
      <canvas
        ref={canvasRef}
        width="320"
        height="320"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        className="scratch-canvas"
      />
      {prize && <p className="prize-text">{prize}</p>}
    </div>
  );
};

export default Raspaditas;
