// Promociones.js
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import logoempanadas from '../../assets/logo.png';
import QRCode from 'qrcode.react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import './CouponDetail.css';

const generateRandomCode = () => Math.random().toString(36).substring(2, 9).toUpperCase();

const formatDate = (date) => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const Popup = ({ code, onClose, title, validFrom, validUntil }) => {
  const qrRef = useRef();

  const handleDownloadPDF = async () => {
    const canvas = await html2canvas(qrRef.current);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a4'
    });
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.text(`Valid from: ${formatDate(validFrom)} to ${formatDate(validUntil)}`, 10, pdfHeight + 10);
    pdf.save(`${title}.pdf`);
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <img src={logoempanadas} alt="Logo Empanadas del Paco" className="popup-logo" />
        <h2 className="popup-title">{title}</h2>
        <div ref={qrRef}>
          <QRCode value={code} size={128} level={"H"} />
        </div>
        <p className="popup-code">{code}</p>
        <p>Valid from {formatDate(validFrom)} to {formatDate(validUntil)}</p>
        <button onClick={onClose}>Cerrar</button>
        <button onClick={handleDownloadPDF}>Descargar PDF</button>
      </div>
    </div>
  );
};

const Promociones = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponType, setCouponType] = useState('');
  const [validFrom, setValidFrom] = useState(new Date());
  const [validUntil, setValidUntil] = useState(new Date());

  const handleGenerateCode = (type) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const thirtyDaysLater = new Date(tomorrow);
    thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);

    const code = generateRandomCode();
    setCouponCode(code);
    setCouponType(type);
    setValidFrom(tomorrow);
    setValidUntil(thirtyDaysLater);
    setShowPopup(true);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logoempanadas} alt="Las Empanadas del Paco" className="header-logo" />
        <Link to="/" className="back-to-menu">Regresar al Menú</Link>
        <h1>Promociones Disponibles</h1>
        <div className="coupon-buttons">
          <button onClick={() => handleGenerateCode('2x1 en siguiente visita')}>2x1 Siguiente Visita - Empanadas del paco</button>
          <button onClick={() => handleGenerateCode('Cupón Delivery')}>Cupón Delivery - La Costeñita</button>
          <button onClick={() => handleGenerateCode('Comparte con un amigo')}>Comparte con un amigo - El Manglar</button>
        </div>
        {showPopup && (
          <Popup
            code={couponCode}
            title={`Cupón - ${couponType}`}
            validFrom={validFrom}
            validUntil={validUntil}
            onClose={() => setShowPopup(false)}
          />
        )}
      </header>
    </div>
  );
};

export default Promociones;
