import React, { useContext } from 'react';
import { AuthContext } from '../auth/AuthContext';
import { Link } from 'react-router-dom';
import './Payments.css';
import logo from './logo.png'; // Asegúrate de que la ruta al logo sea correcta

const Payments = () => {
  const { user } = useContext(AuthContext);

  if (!user || (user.role !== 'admin' && user.role !== 'superadmin')) {
    return <p>Acceso restringido. Solo los administradores pueden acceder a esta página.</p>;
  }

  const plans = [
    {
      name: 'DataTrust Essential',
      price: 100,
      features: [
        'Validación de datos en conformidad legal',
        'Autenticación segura y cifrado de datos',
        'Análisis de datos para insights de cliente',
        'Integración con sistemas de Big Data',
      ],
      link: 'https://payp.page.link/oDv2',
    },
    {
      name: 'PromoBoost AI',
      price: 200,
      features: [
        'Herramientas de marketing con IA',
        'Sistemas de cupones y flyers digitales',
        'Estrategias de raspaditas y promociones',
        'Automatización de campañas de email marketing',
      ],
      link: 'https://payp.page.link/VUVC',
    },
    {
      name: 'AIMarketing Pro',
      price: 300,
      features: [
        'Marketing personalizado con Machine Learning',
        'Generación automatizada de leads cualificados',
        'Análisis predictivo para estrategias de mercado',
        'Optimización de campañas en tiempo real',
      ],
      link: 'https://payp.page.link/rRVd',
    },
  ];

  return (
    <div className="payments-page">
      <header className="logo-header">
        <img src={logo} alt="Logo" className="logo" />
        <Link to="/" className="home-link">Regresar al menú</Link> {/* Botón para regresar al inicio */}
      </header>
      <div className="plans-container">
        {plans.map((plan, index) => (
          <div className={`plan plan-${index}`} key={plan.name}>
            <h2>{plan.name}</h2>
            <h3>${plan.price}</h3>
            <ul>
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex}>
                  <span className="check-mark">&#10003;</span> {feature}
                </li>
              ))}
            </ul>
            <button onClick={() => window.open(plan.link, '_blank')}>Suscripción mensual</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Payments;
