import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import logoEddysBBQ from './logo-eddysbbq.png';
import whatsappIcon from './whatsapp-icon.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const LoginEddysBBQ = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');

    const response = await login(email, password, 'eddys_bbq');

    if (response.success && response.user) {
      navigate(`/home-eddysbbq`);
    } else {
      setLoginError(response.message || 'Error desconocido al iniciar sesión. Intente de nuevo.');
    }
  };

  const handleRegister = () => {
    navigate(`/register-eddysbbq`);
  };

  const handleWhatsAppRedirect = () => {
    window.location.href = 'https://wa.me/593XXXXXXXXX';
  };

  const handleForgotPassword = () => {
    navigate(`/forgot-password/eddys_bbq`);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
      {/* Header con logo y botones */}
      <header className="flex flex-col items-center bg-white shadow-lg rounded-lg py-8 w-full max-w-lg px-6">
        <img src={logoEddysBBQ} alt="Eddy's BBQ Logo" className="w-36 h-36 object-contain" />
        <div className="d-flex flex-wrap justify-content-center mt-4">
          <button className="btn btn-danger mx-2" onClick={handleRegister}>
            Registrarse
          </button>
          <button
            className="btn btn-success mx-2 d-flex align-items-center justify-content-center"
            onClick={handleWhatsAppRedirect}
          >
            <img src={whatsappIcon} alt="WhatsApp" className="h-5 w-5 mr-2" />
            Contactar
          </button>
          <button className="btn btn-primary mx-2" onClick={() => setShowLoginForm(!showLoginForm)}>
            Iniciar Sesión
          </button>
        </div>
      </header>

      {/* Formulario de inicio de sesión */}
      {showLoginForm && (
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md mt-6">
          <button
            className="text-gray-400 text-2xl font-bold absolute top-4 right-4"
            onClick={() => setShowLoginForm(false)}
          >
            ×
          </button>
          <form onSubmit={handleLogin} className="space-y-6">
            <div className="form-group">
              <label htmlFor="email" className="block text-gray-700 font-medium">
                Correo electrónico
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="block text-gray-700 font-medium">
                Contraseña
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {loginError && <p className="text-danger">{loginError}</p>}
            <div className="d-flex justify-content-between align-items-center mb-3">
              <button type="submit" className="btn btn-danger">
                Iniciar Sesión
              </button>
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={handleForgotPassword}
                style={{ color: '#007bff', textDecoration: 'none', cursor: 'pointer' }}
              >
                Olvidé mi contraseña
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Video promocional */}
      <div className="mt-10 w-full max-w-4xl px-4">
        <div className="relative w-full overflow-hidden aspect-w-16 aspect-h-9">
          <iframe
            className="w-full h-full"
            src="https://www.youtube.com/embed/BI7evs5oiXg" // Nueva URL del video
            title="Eddy's BBQ Promo Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* Footer */}
      <footer className="mt-6 text-center text-gray-500 text-sm">
        <p>&copy; 2024 Eddy's BBQ. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default LoginEddysBBQ;
