import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerMenuDeLena = () => {
  const [pdfFileUrl, setPdfFileUrl] = useState('');
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  useEffect(() => {
    const fetchLatestMenuUrl = async () => {
      try {
        const response = await axios.get(`${backendUrl}/latest-menu-url-DeLena`);
        setPdfFileUrl(response.data.pdfUrl);
      } catch (error) {
        console.error('Error fetching the latest menu URL:', error);
      }
    };

    fetchLatestMenuUrl();
  }, [backendUrl]);

  if (!pdfFileUrl) {
    return <div>Cargando menu...</div>;
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <button onClick={goBack} style={{ margin: '10px', position: 'absolute', zIndex: 1000 }}>Volver al Menú</button>
      <iframe
        src={pdfFileUrl}
        title="La Costeñita Menu"
        frameBorder="0"
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default VerMenuDeLena;
