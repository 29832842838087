// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* IAMarketing.css */

.marketing-container {
    /* Ajustes para el contenedor principal si son necesarios */
  }
  
  .logo-container {
    text-align: center; /* Centra el contenedor del logo */
    padding-top: 10px; /* Ajusta este valor como sea necesario */
  }
  
  .logo {
    max-width: 90%; /* Aproximadamente un tercio del ancho del contenedor */
    max-height: 207px; /* Ajusta la altura máxima del logo */
    width: auto; /* Mantiene la relación de aspecto del logo */
    height: auto; /* Mantiene la relación de aspecto del logo */
  }
  
  /* ... (El resto de tu CSS) */

  .back-to-menu {
    position: absolute; /* Adjust the position as needed */
    top: 20px; /* Adjust the placement as needed */
    left: 20px; /* Adjust the placement as needed */
    padding: 10px 20px;
    background-color: #f2f2f2; /* Your desired background color */
    color: #333; /* Your desired text color */
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }`, "",{"version":3,"sources":["webpack://./src/components/empanadasdelpaco/IAMarketing.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,2DAA2D;EAC7D;;EAEA;IACE,kBAAkB,EAAE,kCAAkC;IACtD,iBAAiB,EAAE,yCAAyC;EAC9D;;EAEA;IACE,cAAc,EAAE,uDAAuD;IACvE,iBAAiB,EAAE,qCAAqC;IACxD,WAAW,EAAE,6CAA6C;IAC1D,YAAY,EAAE,6CAA6C;EAC7D;;EAEA,6BAA6B;;EAE7B;IACE,kBAAkB,EAAE,kCAAkC;IACtD,SAAS,EAAE,mCAAmC;IAC9C,UAAU,EAAE,mCAAmC;IAC/C,kBAAkB;IAClB,yBAAyB,EAAE,kCAAkC;IAC7D,WAAW,EAAE,4BAA4B;IACzC,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;EACnB","sourcesContent":["/* IAMarketing.css */\r\n\r\n.marketing-container {\r\n    /* Ajustes para el contenedor principal si son necesarios */\r\n  }\r\n  \r\n  .logo-container {\r\n    text-align: center; /* Centra el contenedor del logo */\r\n    padding-top: 10px; /* Ajusta este valor como sea necesario */\r\n  }\r\n  \r\n  .logo {\r\n    max-width: 90%; /* Aproximadamente un tercio del ancho del contenedor */\r\n    max-height: 207px; /* Ajusta la altura máxima del logo */\r\n    width: auto; /* Mantiene la relación de aspecto del logo */\r\n    height: auto; /* Mantiene la relación de aspecto del logo */\r\n  }\r\n  \r\n  /* ... (El resto de tu CSS) */\r\n\r\n  .back-to-menu {\r\n    position: absolute; /* Adjust the position as needed */\r\n    top: 20px; /* Adjust the placement as needed */\r\n    left: 20px; /* Adjust the placement as needed */\r\n    padding: 10px 20px;\r\n    background-color: #f2f2f2; /* Your desired background color */\r\n    color: #333; /* Your desired text color */\r\n    text-decoration: none;\r\n    border-radius: 5px;\r\n    font-weight: bold;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
