import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { AuthContext } from '../../auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from './logo-lacostenita.png'; // Asegúrate de que la ruta al logo es correcta

Modal.setAppElement('#root');

const Unsubscribe_lacostenita = () => {
  const { user } = useContext(AuthContext);
  const [reason, setReason] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // Nuevo estado para el modal de éxito
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const openConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleUnsubscribe = async () => {
    try {
      const response = await fetch(`${backendUrl}/unsubscribe_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: user.email,
          client: user.client,
          reason: reason
        })
      });

      if (response.ok) {
        setIsSuccessModalOpen(true); // Abrir el modal de éxito en lugar de mostrar un mensaje en la misma página
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to unsubscribe');
      }
    } catch (error) {
      console.error('Unsubscribe error:', error);
    }
  };

  const redirectToLogin = () => {
    navigate('login/la_costenita');
  };

  return (
    <div className="unsubscribe-container">
      {/* Botón de regreso al menú y logo */}
      <div className="header">
        <Link to="/" className="back-to-menu">Regresar al Menú</Link>
        <img src={logo} alt="Logo La Costeñita" className="logo" />
      </div>
      <h2>Desuscripción del Club de La Costeñita</h2>
      <textarea
        value={reason}
        onChange={handleReasonChange}
        placeholder="Explica por qué deseas desuscribirte..."
        rows="5"
        cols="50"
      />
      <button onClick={openConfirmModal}>Desuscribirse</button>
      <Modal
        isOpen={isConfirmModalOpen}
        onRequestClose={closeConfirmModal}
        contentLabel="Confirm Unsubscription"
      >
        <h2>¿Estás seguro que deseas desuscribirte? 😢</h2>
        <button onClick={handleUnsubscribe}>Confirmar</button>
        <button onClick={closeConfirmModal}>Cancelar</button>
      </Modal>
      {/* Modal de éxito */}
      <Modal
        isOpen={isSuccessModalOpen}
        onRequestClose={() => setIsSuccessModalOpen(false)}
        contentLabel="Unsubscription Success"
      >
        <h2>Listo, gracias por haber sido parte de nosotros. 😊 Te extrañaremos 😢</h2>
        <button onClick={redirectToLogin}>Volver al inicio</button>
      </Modal>
    </div>
  );
};

export default Unsubscribe_lacostenita;
