import React, { useState } from 'react';

import PaymentOption from './PaymentOption'; // Asegúrate de que el path es correcto

const Paycrest = () => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handlePaymentClick = () => {
    setShowPaymentModal(true);
  };

  return (
    <div>
      <button onClick={handlePaymentClick}>Pagar</button>
      {showPaymentModal && <PaymentOption />}
    </div>
  );
};

export default Paycrest;
