import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';

const LoginSuperAdmin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

    try {
      const response = await fetch(`${backendUrl}/login-superadmin`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
      });

      const data = await response.json();
      if (response.ok) {
        // Aquí puedes agregar una verificación adicional si es necesario
        if (data.user.role === 'superadmin') {
          setUser(data.user);
          navigate('/home_siemprecondescuentos');
        } else {
          setLoginError('No tiene privilegios de superadministrador.');
        }
      } else {
        setLoginError(data.message || `Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setLoginError(`Error: ${error.message}`);
    }
  };

  return (
    <form className="auth" onSubmit={handleLogin}>
      <h2>Iniciar Sesión Superadministrador</h2>
      <input
        type="email"
        placeholder="Correo Electrónico"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Contraseña"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <button type="submit">Iniciar Sesión</button>
      {loginError && <p>{loginError}</p>}
    </form>
  );
};

export default LoginSuperAdmin;
