import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import pinIcon from './pin.png';

const customIcon = new L.Icon({
  iconUrl: pinIcon,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

const ProgramarComidas = ({ onReset }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedDates, setSelectedDates] = useState([]);
  const [mealsByDate, setMealsByDate] = useState({});
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [mapLocation, setMapLocation] = useState([19.432608, -99.133209]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [availableMeals, setAvailableMeals] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const resetComponent = () => {
    setSelectedDates([]);
    setMealsByDate({});
    setDeliveryAddress('');
    setMapLocation([19.432608, -99.133209]);
    setAdditionalDetails('');
    setError('');
    setShowSummary(false);
    setTotalCost(0);
    setSuccessMessage(false);
  };

  const handleReturnToHome = () => {
    resetComponent();
    if (onReset) onReset();
    navigate('/home-foodlove');
  };

  useEffect(() => {
    const fetchUserBalance = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/${user.id}`);
        if (response.ok) {
          const data = await response.json();
          const balance = parseFloat(data.saldo) || 0;
          setUserBalance(balance);
        } else {
          setFetchError(true);
        }
      } catch (error) {
        setFetchError(true);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/products`);
        if (response.ok) {
          const data = await response.json();
          setAvailableMeals(data);
          setFetchError(false);
        } else {
          setFetchError(true);
        }
      } catch (error) {
        setFetchError(true);
      }
    };

    fetchUserBalance();
    fetchProducts();
  }, [user.id]);

  const handleDateChange = (date) => {
    const formattedDate = date.toISOString();
    if (selectedDates.includes(formattedDate)) {
      setSelectedDates(selectedDates.filter((d) => d !== formattedDate));
      const newMealsByDate = { ...mealsByDate };
      delete newMealsByDate[formattedDate];
      setMealsByDate(newMealsByDate);
    } else {
      setSelectedDates([...selectedDates, formattedDate]);
      setMealsByDate({
        ...mealsByDate,
        [formattedDate]: {},
      });
    }
  };

  const handleMealSelection = (date, mealId, quantity) => {
    setMealsByDate((prevMeals) => ({
      ...prevMeals,
      [date]: {
        ...prevMeals[date],
        [mealId]: quantity,
      },
    }));
  };

  const handleAddressChange = (e) => {
    setDeliveryAddress(e.target.value);
  };

  const handleAdditionalDetailsChange = (e) => {
    setAdditionalDetails(e.target.value);
  };

  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        setMapLocation([e.latlng.lat, e.latlng.lng]);
        getAddressFromCoordinates(e.latlng.lat, e.latlng.lng);
      },
    });
    return <Marker position={mapLocation} icon={customIcon}></Marker>;
  };

  const getAddressFromCoordinates = async (lat, lng) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://us1.locationiq.com/v1/reverse.php?key=pk.b3cf34b8d099cc500fb41a11731e9f97&lat=${lat}&lon=${lng}&format=json`
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      if (data.display_name) {
        setDeliveryAddress(data.display_name);
        setError('');
      } else {
        setError('No se pudo obtener la dirección.');
      }
    } catch (error) {
      setError('Error al obtener la dirección. Verifica tu conexión o la clave API.');
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalCost = () => {
    let total = 0;
    Object.keys(mealsByDate).forEach((date) => {
      Object.keys(mealsByDate[date]).forEach((mealId) => {
        const meal = availableMeals.find((m) => m.id === parseInt(mealId));
        const quantity = mealsByDate[date][mealId];
        if (meal && quantity) {
          total += meal.price * quantity;
        }
      });
    });
    setTotalCost(total);
  };

  const handleValidateAndSubmit = () => {
    calculateTotalCost();
    if (userBalance >= totalCost) {
      setShowSummary(true);
    } else {
      alert(`Saldo insuficiente. Tienes ${userBalance}, pero necesitas ${totalCost} para completar el pedido.`);
    }
  };

  const handleConfirmOrder = async () => {
    if (selectedDates.length === 0 || !deliveryAddress) {
      alert('Por favor, selecciona fechas y proporciona una dirección de entrega.');
      return;
    }

    calculateTotalCost();

    setIsSubmitting(true);

    const mealNames = {};
    availableMeals.forEach(meal => {
      mealNames[meal.id] = meal.name;
    });

    const requestBody = {
      userId: user.id,
      mealsByDate,
      selectedDates: selectedDates.map((date) => date),
      deliveryAddress,
      mapLocation,
      additionalDetails,
      totalCost,
      userBalance,
      mealNames
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/orders/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const newBalance = userBalance - totalCost;
        setUserBalance(newBalance);
        setSuccessMessage(true);
        setShowSummary(false);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      alert('Hubo un error al programar las comidas. Inténtalo nuevamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center text-yellow-500 mb-4">Programar Comidas</h2>
  
      {fetchError && (
        <div className="alert alert-danger">
          Error al cargar datos, por favor intenta de nuevo más tarde.
        </div>
      )}
  
      {!fetchError && (
        <>
          <div className="mb-4">
            <h5 className="text-white">Selecciona las fechas para recibir tus comidas:</h5>
            <DatePicker
              selected={null}
              onChange={handleDateChange}
              inline
              multiple
              highlightDates={selectedDates}
            />
            <p className="text-white">Fechas seleccionadas: {selectedDates.map((date) => new Date(date).toLocaleDateString()).join(', ')}</p>
          </div>
  
          <div className="mb-4">
            <h5 className="text-white">Selecciona las comidas para cada fecha:</h5>
            {selectedDates.map((date) => (
              <div key={date}>
                <h6 className="text-white">Comidas para el día {new Date(date).toLocaleDateString()}:</h6>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {availableMeals.map(meal => (
                    <div key={meal.id} className="meal-item p-4 rounded shadow-md text-center bg-gray-800 text-white">
                      <h6 className="font-bold">{meal.name}</h6>
                      <p>{meal.description}</p>
                      <input
                        type="number"
                        placeholder="Cantidad"
                        min="0"
                        className="form-control mt-2"
                        onChange={(e) => handleMealSelection(date, meal.id, parseInt(e.target.value))}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
  
          <div className="mb-4">
            <h5 className="text-white">Proporciona la dirección de entrega:</h5>
            <input
              type="text"
              className="form-control bg-white text-black"
              placeholder="Dirección de entrega"
              value={deliveryAddress}
              onChange={handleAddressChange}
            />
          </div>
  
          <div className="mb-4">
            <h5 className="text-white">Detalles adicionales:</h5>
            <input
              type="text"
              className="form-control bg-white text-black"
              placeholder="Detalles adicionales (ej. referencias, piso, etc.)"
              value={additionalDetails}
              onChange={handleAdditionalDetailsChange}
            />
          </div>
  
          <div className="mb-4" style={{ zIndex: 1 }}>
            <h5 className="text-white">Selecciona la ubicación en el mapa:</h5>
            <MapContainer
              center={mapLocation}
              zoom={13}
              scrollWheelZoom={false}
              style={{ height: '300px', width: '100%' }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; OpenStreetMap contributors"
              />
              <LocationMarker />
            </MapContainer>
          </div>
  
          <button className="btn btn-primary w-full py-2 mt-4" onClick={handleValidateAndSubmit} disabled={isSubmitting}>
            {isSubmitting ? 'Validando...' : 'Programar Comidas'}
          </button>
        </>
      )}
  
      {showSummary && (
        <div className="modal-overlay" style={{
          position: 'fixed', top: '0', left: '0', right: '0', bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center',
          alignItems: 'center', zIndex: 9999
        }}>
          <div className="modal-content" style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', width: '50%' }}>
            <h5 className="text-center text-danger">Resumen del Pedido</h5>
            {selectedDates.map((date) => (
              <div key={date}>
                <p><strong>Día {new Date(date).toLocaleDateString()}:</strong></p>
                {Object.keys(mealsByDate[date] || {}).map((mealId) => {
                  const meal = availableMeals.find((m) => m.id === parseInt(mealId));
                  return (
                    <p key={mealId}>
                      {meal ? meal.name : 'Comida no encontrada'} - Cantidad: {mealsByDate[date][mealId]}
                    </p>
                  );
                })}
              </div>
            ))}
            <p><strong>Dirección de entrega:</strong> {deliveryAddress}</p>
            <p><strong>Detalles adicionales:</strong> {additionalDetails}</p>
            <p><strong>Total a pagar:</strong> ${totalCost}</p>
            <p><strong>Saldo actual:</strong> ${userBalance}</p>
            <p><strong>Saldo después del pedido:</strong> ${userBalance - totalCost}</p>
  
            {userBalance < totalCost && (
              <p className="text-danger">Saldo insuficiente para completar la compra.</p>
            )}
  
            <div className="modal-footer d-flex justify-content-between">
              <button className="btn btn-secondary" onClick={() => setShowSummary(false)}>
                Cancelar
              </button>
              <button className="btn btn-primary" onClick={handleConfirmOrder} disabled={userBalance < totalCost}>
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
  
      {successMessage && (
        <div className="modal-overlay" style={{
          position: 'fixed', top: '0', left: '0', right: '0', bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center',
          alignItems: 'center', zIndex: 9999
        }}>
          <div className="modal-content" style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', width: '50%' }}>
            <h5 className="text-center text-success">¡Pedido Registrado con Éxito!</h5>
            <p className="text-center">Te enviaremos un correo electrónico con los detalles de tu pedido.</p>
            <div className="d-flex justify-content-center mt-4">
              <button 
                className="btn btn-primary" 
                onClick={handleReturnToHome}
              >
                Regresar al Inicio
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgramarComidas;