import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 

const IntegracionesRestaurante = () => {
    const [datos, setDatos] = useState([]);
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${backendUrl}/integraciones`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setDatos(data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
        }
      };
  
      fetchData();
    }, [backendUrl]);

   // Style object for the back to menu button
   const backToMenuStyle = {
    display: 'inline-block',
    margin: '1rem',
    padding: '0.5rem 1rem',
    backgroundColor: '#f0f0f0',
    color: '#333',
    borderRadius: '0.3rem',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

return (
  <div>
    <Link to="/" style={backToMenuStyle}>Regresar al Menú</Link> {/* Add this line for the "Regresar al Menú" button */}
    <h1>Integraciones con Restaurante.pe</h1>
      {/* Renderiza la tabla de datos aquí */}
      <table>
        <thead>
          <tr>
            {/* Asume que tienes estas columnas, ajusta según tus datos */}
            <th>Categoria</th>
            <th>Proveedor</th>
            <th>Código</th>
            {/* Más columnas... */}
          </tr>
        </thead>
        <tbody>
          {datos.map((dato, index) => (
            <tr key={index}>
              {/* Asume que tus datos tienen estas propiedades, ajusta según tus datos */}
              <td>{dato.categoria}</td>
              <td>{dato.proveedor}</td>
              <td>{dato.codigo}</td>
              {/* Más celdas... */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IntegracionesRestaurante;
