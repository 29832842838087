import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../auth/AuthContext';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import logo from './logo-empanadasdepaco.png'; // Logo for the page header
import PromoEmpanadas from './PromoEmpanadas.jpg'; // Image to show in the thank-you modal

// Set the root app element for accessibility with modals
Modal.setAppElement('#root');

const RespuestasEncuesta = () => {
    const { user, token } = useContext(AuthContext);
    const [encuestas, setEncuestas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showThankYouModal, setShowThankYouModal] = useState(false); // State to control modal visibility
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

    // Function to fetch surveys
    const fetchEncuestas = async () => {
        if (!user?.id) {
            setError('User ID not available. Please log in.');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${backendUrl}/api/encuestas`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const activas = response.data.filter(e => e.activa && (!e.respondientes || !e.respondientes.includes(user.id)));
            const encuestasConPreguntas = await Promise.all(activas.map(async encuesta => {
                const resp = await axios.get(`${backendUrl}/api/encuestas/${encuesta.id}/preguntas`);
                return { ...encuesta, preguntas: resp.data, respuestas: [] };
            }));
            setEncuestas(encuestasConPreguntas);
        } catch (error) {
            console.error('Error al cargar las encuestas:', error);
            setError('No se pudieron cargar las encuestas');
        } finally {
            setLoading(false);
        }
    };

    // UseEffect to trigger the loading of surveys
    useEffect(() => {
        fetchEncuestas();
    }, [user?.id, token]);

    // Handler for submitting responses
    const handleSubmit = async (encuestaId, respuestas) => {
        if (!user?.id) {
            alert('User ID is missing, please login again.');
            return;
        }

        try {
            const formattedRespuestas = respuestas.map(r => ({
                preguntaId: r.preguntaId,
                respuesta: r.valor
            }));

            const response = await axios.post(`${backendUrl}/api/encuestas/${encuestaId}/respuestas`, {
                usuarioId: user.id,
                respuestas: formattedRespuestas
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.data) {
                setShowThankYouModal(true); // Show thank you modal on successful response submission
                fetchEncuestas();  // Optionally refresh surveys to reflect that they've been responded to
            }
        } catch (error) {
            console.error('Error al enviar respuestas:', error.response ? error.response.data : error);
            alert('Error al enviar respuestas');
        }
    };

    const closeModal = () => {
        setShowThankYouModal(false);
    };

    if (loading) return <p>Cargando encuestas...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <Link to="/" className="logo-link">
                <img src={logo} alt="Logo Empanadas del Paco" className="card-logo" />
            </Link>
            <h1>Encuestas Activas</h1>
            {encuestas.length > 0 ? (
                <ul>
                    {encuestas.map(encuesta => (
                        <li key={encuesta.id}>
                            <h2>{encuesta.titulo}</h2>
                            {encuesta.preguntas.map(pregunta => (
                                <div key={pregunta.id}>
                                    <p>{pregunta.texto}</p>
                                    <input type="text" onChange={e => {
                                        const newRespuesta = { preguntaId: pregunta.id, valor: e.target.value };
                                        const existingRespuestas = encuesta.respuestas.filter(resp => resp.preguntaId !== pregunta.id);
                                        encuesta.respuestas = [...existingRespuestas, newRespuesta];
                                    }} />
                                </div>
                            ))}
                            <button onClick={() => handleSubmit(encuesta.id, encuesta.respuestas)}>
                                Enviar Respuestas
                            </button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No hay encuestas disponibles para responder.</p>
            )}
            <Modal
                isOpen={showThankYouModal}
                onRequestClose={closeModal}
                contentLabel="Thank You Modal"
                className="modal"
            >
                <div className="modal-content">
                    <img src={PromoEmpanadas} alt="Thank You" />
                    <h2>¡Gracias por responder la encuesta!</h2>
                    <p>Tu opinión es muy importante para nosotros.</p>
                    <button onClick={closeModal}>Cerrar</button>
                </div>
            </Modal>
        </div>
    );
};

export default RespuestasEncuesta;
