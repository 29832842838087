import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

const CreateSurveyModal = ({
  survey,
  setSurvey,
  handleInputChange,
  handleDateChange,
  handleAddOption,
  handleUpdateOption,
  handleAddQuestion,
  handleUpdateQuestion,
  handleSubmit,
  closeModal
}) => {
  const [optionText, setOptionText] = useState('');

  const addOption = (index) => {
    const updatedQuestions = survey.questions.map((q, idx) => {
      if (index === idx) {
        return { ...q, opciones: [...(q.opciones || []), optionText] };
      }
      return q;
    });
    setSurvey({ ...survey, questions: updatedQuestions });
    setOptionText('');
  };

  const validateSurveyData = () => {
    if (!survey.title.trim()) {
      alert('El título de la encuesta es obligatorio.');
      return false;
    }
    if (!survey.duration.start || !survey.duration.end) {
      alert('La duración de la encuesta es obligatoria.');
      return false;
    }
    for (const question of survey.questions) {
      if (!question.question_text?.trim()) {
        alert('El texto de la pregunta es obligatorio.');
        return false;
      }
      if (!question.question_type?.trim()) {
        alert('El tipo de pregunta es obligatorio.');
        return false;
      }
      if (question.question_type === 'multiple_choice' && (!question.opciones || question.opciones.length === 0)) {
        alert('Las opciones de respuesta son obligatorias para preguntas de respuesta múltiple.');
        return false;
      }
    }
    return true;
  };

  const submitSurvey = async () => {
    try {
      await axios.post(`${backendUrl}/api/encuestasxy`, {
        title: survey.title,
        branches: survey.branches,
        startDate: survey.duration.start.toISOString(),
        endDate: survey.duration.end.toISOString(),
        status: survey.status,
        questions: survey.questions
      });
      alert('Encuesta creada con éxito!');
      handleSubmit(); // Reiniciar el formulario o realizar otras acciones necesarias
    } catch (error) {
      console.error('Error al crear la encuesta:', error);
      alert('Error al crear la encuesta. Por favor, intente de nuevo.');
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={closeModal}>&times;</span>
        <h2>Nueva Encuesta</h2>
        <div className="input-group">
          <label htmlFor="title">Título de la encuesta</label>
          <input
            type="text"
            name="title"
            value={survey.title}
            onChange={handleInputChange}
            placeholder="Título de la encuesta"
          />
        </div>
        <div className="input-group">
          <label htmlFor="duration">Duración de la encuesta</label>
          <DatePicker
            selectsRange
            startDate={survey.duration.start}
            endDate={survey.duration.end}
            onChange={handleDateChange}
            isClearable={true}
          />
        </div>
        <div className="input-group">
          <label htmlFor="questionText">Texto de la pregunta</label>
          <input
            type="text"
            name="questionText"
            value={survey.questionText}
            onChange={e => setSurvey({ ...survey, questionText: e.target.value })}
            placeholder="Texto de la pregunta"
          />
        </div>
        <div className="input-group">
          <label htmlFor="questionType">Tipo de pregunta</label>
          <select
            name="questionType"
            value={survey.questionType}
            onChange={e => setSurvey({ ...survey, questionType: e.target.value })}
          >
            <option value="multiple_choice">Respuesta múltiple</option>
            <option value="open_ended">Pregunta abierta</option>
          </select>
        </div>
        <button className="button" onClick={handleAddQuestion}>Agregar Pregunta</button>
        <div>
          {survey.questions.map((q, index) => (
            <div key={index} className="question-item">
              <input
                type="text"
                value={q.question_text || q.texto}
                onChange={e => handleUpdateQuestion(index, e.target.value)}
                placeholder="Texto de la pregunta"
              />
              {q.question_type === 'multiple_choice' && (
                <div>
                  {q.opciones && q.opciones.map((opcion, idx) => (
                    <div key={idx} className="input-group">
                      <input
                        type="text"
                        value={opcion}
                        onChange={e => handleUpdateOption(index, idx, e.target.value)}
                        placeholder="Opción de respuesta"
                      />
                    </div>
                  ))}
                  <div className="input-group">
                    <input
                      type="text"
                      value={optionText}
                      onChange={e => setOptionText(e.target.value)}
                      placeholder="Nueva opción"
                    />
                    <button className="button" onClick={() => addOption(index)}>Agregar Opción</button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <button className="button" onClick={submitSurvey}>Crear Encuesta</button>
      </div>
    </div>
  );
};

export default CreateSurveyModal;
