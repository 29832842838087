import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import logoEmpanadas from './logo-empanadasdepaco.png';
import logoManglar from './logo_manglar.jpg';
import logoCostenita from './logo_lacostenita.png';
import logoEddys from './eddys.png';
import logoDelena from './logo-DeLena.png';
import './Main.css'; // Asegúrate de crear este archivo CSS

const Main = () => {
  const navigate = useNavigate();

  const navigateToClientLogin = (clientPath) => {
    navigate(clientPath);
  };

  return (
    <Container fluid className="main-container">
      <h1 className="main-title">Bienvenido, por favor selecciona tu marca favorita:</h1>
      <Row className="justify-content-center brand-grid">
        <Col xs={6} md={4} lg={3} className="mb-4">
          <Button 
            className="brand-button empanadas"
            onClick={() => navigateToClientLogin('/login/Empanadas_del_paco')}
          >
            <img src={logoEmpanadas} alt="Empanadas del Paco" />
          </Button>
        </Col>
        <Col xs={6} md={4} lg={3} className="mb-4">
          <Button 
            className="brand-button manglar"
            onClick={() => navigateToClientLogin('/login/el_manglar')}
          >
            <img src={logoManglar} alt="El Manglar" />
          </Button>
        </Col>
        <Col xs={6} md={4} lg={3} className="mb-4">
          <Button 
            className="brand-button costenita"
            onClick={() => navigateToClientLogin('/login/la_costenita')}
          >
            <img src={logoCostenita} alt="La Costenita" />
          </Button>
        </Col>
        <Col xs={6} md={4} lg={3} className="mb-4">
          <Button 
            className="brand-button eddys"
            onClick={() => navigateToClientLogin('/login/eddys_bbq')}
          >
            <img src={logoEddys} alt="Eddy's BBQ" />
          </Button>
        </Col>
        <Col xs={6} md={4} lg={3} className="mb-4">
          <Button 
            className="brand-button encuestadora"
            onClick={() => navigateToClientLogin('/login/encuestadora_xy')}
          >
            <span>Encuestadora XY</span>
          </Button>
        </Col>
        <Col xs={6} md={4} lg={3} className="mb-4">
          <Button 
            className="brand-button nuevas-marcas"
            onClick={() => navigateToClientLogin('/nuevas-marcas')}
          >
            <span>Nuevas Marcas</span>
          </Button>
        </Col>
        <Col xs={6} md={4} lg={3} className="mb-4">
          <Button 
            className="brand-button delena"
            onClick={() => navigateToClientLogin('/login/delena')}
          >
            <img src={logoDelena} alt="De Leña" />
          </Button>
        </Col>
        <Col xs={6} md={4} lg={3} className="mb-4">
          <Button 
            className="brand-button foodlove"
            onClick={() => navigateToClientLogin('/login/foodlove')}
          >
            <span>FoodLove</span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Main;