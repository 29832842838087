// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-panel {
    background: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .admin-panel h1 {
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .admin-panel form {
    display: flex;
    flex-direction: column;
  }
  
  .admin-panel label {
    margin-bottom: 10px;
    color: #666;
    font-family: 'Arial', sans-serif;
  }
  
  .admin-panel input[type="text"],
  .admin-panel input[type="file"] {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .admin-panel input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .admin-panel button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .admin-panel button:hover {
    background-color: #004494;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/HomeSuperAdmin.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,qCAAqC;EACvC;;EAEA;IACE,WAAW;IACX,gCAAgC;EAClC;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;IACnB,WAAW;IACX,gCAAgC;EAClC;;EAEA;;IAEE,aAAa;IACb,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".admin-panel {\r\n    background: #f4f4f4;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    box-shadow: 0 4px 8px rgba(0,0,0,0.1);\r\n  }\r\n  \r\n  .admin-panel h1 {\r\n    color: #333;\r\n    font-family: 'Arial', sans-serif;\r\n  }\r\n  \r\n  .admin-panel form {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n  \r\n  .admin-panel label {\r\n    margin-bottom: 10px;\r\n    color: #666;\r\n    font-family: 'Arial', sans-serif;\r\n  }\r\n  \r\n  .admin-panel input[type=\"text\"],\r\n  .admin-panel input[type=\"file\"] {\r\n    padding: 10px;\r\n    border-radius: 4px;\r\n    border: 1px solid #ccc;\r\n  }\r\n  \r\n  .admin-panel input[type=\"checkbox\"] {\r\n    margin-right: 5px;\r\n  }\r\n  \r\n  .admin-panel button {\r\n    background-color: #0056b3;\r\n    color: white;\r\n    border: none;\r\n    padding: 10px 20px;\r\n    cursor: pointer;\r\n    border-radius: 5px;\r\n    margin-top: 20px;\r\n  }\r\n  \r\n  .admin-panel button:hover {\r\n    background-color: #004494;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
