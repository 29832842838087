import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import SignatureCanvas from 'react-signature-canvas';
import './NuevasMarcas.css';

Modal.setAppElement('#root');

const NuevasMarcas = () => {
  const [adminName, setAdminName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [brandName, setBrandName] = useState('');
  const [logo, setLogo] = useState(null);
  const [promotionImage, setPromotionImage] = useState(null);
  const [color, setColor] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [cashierEmail, setCashierEmail] = useState('');
  const [cashierPassword, setCashierPassword] = useState('');
  const [products, setProducts] = useState([]);
  const [signatureData, setSignatureData] = useState('');
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const signatureRef = useRef(null);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  const handleProductChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setProducts([...products, value]);
    } else {
      setProducts(products.filter((product) => product !== value));
    }
  };

  const handleAcceptTerms = () => {
    if (signatureRef.current && !signatureRef.current.isEmpty()) {
      setSignatureData(signatureRef.current.toDataURL());
      setIsTermsModalOpen(false);
    } else {
      setMensaje('Debe firmar antes de aceptar los términos y condiciones.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('adminName', adminName);
    formData.append('adminEmail', adminEmail);
    formData.append('adminPassword', adminPassword);
    formData.append('brandName', brandName);
    formData.append('logo', logo);
    formData.append('promotionImage', promotionImage);
    formData.append('color', color);
    formData.append('whatsapp', whatsapp);
    formData.append('cashierEmail', cashierEmail);
    formData.append('cashierPassword', cashierPassword);
    formData.append('products', JSON.stringify(products));
    formData.append('signatureData', signatureData);

    try {
      const response = await fetch(`${backendUrl}/nuevas-marcas`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setMensaje('Marca registrada exitosamente');
        // Limpiar los campos después de un registro exitoso
        setAdminName('');
        setAdminEmail('');
        setAdminPassword('');
        setBrandName('');
        setLogo(null);
        setPromotionImage(null);
        setColor('');
        setWhatsapp('');
        setCashierEmail('');
        setCashierPassword('');
        setProducts([]);
        setSignatureData('');
        setIsRegistrationSuccessful(true);
        setIsWelcomeModalOpen(true); // Abre el nuevo modal
      } else {
        setMensaje('Error al registrar la marca');
      }
    } catch (error) {
      console.error('Error:', error);
      setMensaje('Error al registrar la marca');
    }
  };

  const openTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const termsContent = (
    <div className="terms-content">
      <h2>Términos y Condiciones</h2>
      <p>Términos y Condiciones Generales para la Recopilación de Datos con Fines de Email Marketing y Estudios de Mercado en Ecuador</p>
      {/* Coloca aquí los términos y condiciones reales */}
      {/* Introducción */}
      <h3>Introducción</h3>
      <p>1.1. Propósito de los Términos: Estos términos y condiciones (en adelante, "Términos") tienen como finalidad regular el uso de nuestros servicios de registro manual y la recopilación de datos personales con el fin de llevar a cabo actividades de email marketing y estudios de mercado internos.</p>
      <p>1.2. Aceptación de los Términos: Al utilizar nuestros servicios, usted acepta y se compromete a cumplir con estos Términos en su totalidad. Si no está de acuerdo con alguno de los términos aquí establecidos, le recomendamos que no utilice nuestros servicios.</p>
      {/* Recopilación de Datos del Usuario */}
      <h3>Recopilación de Datos del Usuario</h3>
      <p>2.1. Tipos de Datos Recopilados: Durante el proceso de registro, se solicitará al usuario que proporcione información personal, incluyendo, pero sin limitarse a:</p>
      <ul>
        <li>Nombre completo.</li>
        <li>Dirección de correo electrónico.</li>
        <li>Número de teléfono.</li>
        <li>Contraseña.</li>
      </ul>
      <p>2.2. Métodos de Recopilación de Datos: Los datos serán recopilados a través de formularios de registro en línea y podrán ser actualizados por el usuario en cualquier momento.</p>
      {/* Uso de los Datos */}
      <h3>Uso de los Datos</h3>
      <p>3.1. Email Marketing: Los datos recopilados podrán ser utilizados para enviar comunicaciones de marketing por correo electrónico, incluyendo newsletters, promociones y actualizaciones relevantes.</p>
      <p>3.2. Estudios de Mercado Internos: Los datos podrán ser utilizados para realizar análisis y estudios de mercado internos con el objetivo de mejorar nuestros productos y servicios.</p>
      {/* Protección de Datos y Privacidad */}
      <h3>Protección de Datos y Privacidad</h3>
      <p>4.1. Compromiso con la Privacidad del Usuario: Nos comprometemos a proteger la privacidad de los datos personales proporcionados por los usuarios y a cumplir con todas las leyes de privacidad de datos vigentes en Ecuador.</p>
      <p>4.2. Medidas de Protección de Datos: Implementamos medidas de seguridad adecuadas para proteger la integridad y confidencialidad de los datos personales. Sin embargo, no podemos garantizar la seguridad absoluta de la información transmitida por Internet.</p>
      {/* Derechos del Usuario */}
      <h3>Derechos del Usuario</h3>
      <p>5.1. Acceso a los Datos: Los usuarios tienen derecho a acceder a sus datos personales en cualquier momento y a solicitar información sobre su uso.</p>
      <p>5.2. Corrección y Eliminación de Datos: Los usuarios pueden solicitar la corrección o eliminación de sus datos personales de nuestra base de datos en cualquier momento.</p>
      {/* Cookies y Tecnologías de Seguimiento */}
      <h3>Cookies y Tecnologías de Seguimiento</h3>
      <p>6.1. Uso de Cookies: Utilizamos cookies y tecnologías de seguimiento para mejorar la experiencia del usuario. Los usuarios pueden configurar sus preferencias de cookies en cualquier momento.</p>
      <p>6.2. Control de Seguimiento: Proporcionamos opciones para que los usuarios controlen las cookies y otras tecnologías de seguimiento utilizadas en nuestro sitio web.</p>
      {/* Enlaces y Servicios de Terceros */}
      <h3>Enlaces y Servicios de Terceros</h3>
      <p>7.1. Interacción con Servicios de Terceros: Nuestro sitio web puede contener enlaces o integraciones con servicios de terceros. No somos responsables de las políticas de privacidad o prácticas de estos terceros.</p>
      <p>7.2. Descargo de Responsabilidad para Enlaces de Terceros: Los usuarios deben ejercer precaución al interactuar con enlaces de terceros y deben revisar las políticas de privacidad de los sitios web externos.</p>
      {/* Responsabilidades del Usuario */}
      <h3>Responsabilidades del Usuario</h3>
      <p>8.1. Proporcionar Información Exacta: Los usuarios se comprometen a proporcionar información precisa y actualizada durante el proceso de registro y a mantener sus datos personales actualizados.</p>
      <p>8.2. Prohibiciones de Uso Incorrecto: Los usuarios se comprometen a no hacer un uso indebido de nuestros servicios, incluyendo el envío de contenido ofensivo, ilegal o no autorizado.</p>
      {/* Modificaciones a los Términos */}
      <h3>Modificaciones a los Términos</h3>
      <p>9.1. Notificación de Cambios: Nos reservamos el derecho de modificar estos Términos en cualquier momento. Los cambios se comunicarán a los usuarios a través de nuestro sitio web o por correo electrónico.</p>
      <p>9.2. Aceptación de Cambios: El uso continuado de nuestros servicios después de cualquier modificación de estos Términos implicará la aceptación de los cambios por parte del usuario.</p>
      {/* Ley Aplicable y Jurisdicción */}
      <h3>Ley Aplicable y Jurisdicción</h3>
      <p>10.1. Leyes Aplicables: Estos Términos se rigen por las leyes de Ecuador.</p>
      <p>10.2. Jurisdicción Competente: Cualquier disputa relacionada con estos Términos estará sujeta a la jurisdicción de los tribunales de Ecuador.</p>
      {/* Información de Contacto */}
      <h3>Información de Contacto</h3>
      <p>11.1. Cómo Contactarnos: Los usuarios pueden ponerse en contacto con nosotros en cualquier momento para plantear preguntas o preocupaciones relacionadas con la privacidad de datos.</p>
      {/* Anexos */}
      <h3>Anexos</h3>
      <p>12.1. Leyes y Regulaciones de Ecuador: Se adjuntan anexos con las leyes y regulaciones de protección de datos aplicables en Ecuador.</p>
      <p>12.2. Política de Privacidad Detallada: Consulte nuestra Política de Privacidad para obtener información más detallada sobre cómo manejamos los datos personales.</p>
      {/* Firma Digital */}
      <h3>Firma Digital</h3>
      <p>Para completar su registro, debe firmar digitalmente estos Términos y Condiciones. Utilice el área de firma a continuación para hacerlo.</p>
      <div style={{ border: '1px solid black', padding: '10px', marginTop: '10px' }}>
        <SignatureCanvas
          ref={signatureRef}
          penColor='black'
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
        />
        <p>Firme dentro de este cuadro</p>
      </div>
      <button onClick={handleAcceptTerms}>Aceptar y firmar</button>
      <button onClick={() => setIsTermsModalOpen(false)}>Cerrar</button>
    </div>
  );

  const welcomeContent = (
    <div className="welcome-content">
      <h2>¡Registro Exitoso! 😀</h2>
      <p>En las próximas 48 horas nos pondremos en contacto contigo para darte acceso a tu portal de Siempre con Descuentos personalizado para tu marca.</p>
      <p>¡Bienvenido Socio!</p>
      <button onClick={() => navigate('/')}>Volver a Inicio</button>
    </div>
  );

  return (
    <div className="nuevas-marcas-container">
      <h1 className="nuevas-marcas-header">Registro Admin y datos de la marca</h1>
      <form onSubmit={handleSubmit} className="nuevas-marcas-form">
        <div>
          <label>Nombre Completo del Admin:</label>
          <input type="text" value={adminName} onChange={(e) => setAdminName(e.target.value)} required />
        </div>
        <div>
          <label>Correo Electrónico del Admin:</label>
          <input type="email" value={adminEmail} onChange={(e) => setAdminEmail(e.target.value)} required />
        </div>
        <div>
          <label>Contraseña del Admin:</label>
          <input type="password" value={adminPassword} onChange={(e) => setAdminPassword(e.target.value)} required />
        </div>
        <div>
          <label>Nombre de la Marca:</label>
          <input type="text" value={brandName} onChange={(e) => setBrandName(e.target.value)} required />
        </div>
        <div>
          <label>Logo de la Marca:</label>
          <input type="file" onChange={(e) => setLogo(e.target.files[0])} required />
        </div>
        <div>
          <label>Imagen de la Promoción:</label>
          <input type="file" onChange={(e) => setPromotionImage(e.target.files[0])} required />
        </div>
        <div>
          <label>Color de la Marca:</label>
          <input type="color" value={color} onChange={(e) => setColor(e.target.value)} required />
        </div>
        <div>
          <label>Número de Whatsapp Business:</label>
          <input type="text" value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} required />
        </div>
        <div>
          <label>Correo Electrónico del Cajero:</label>
          <input type="email" value={cashierEmail} onChange={(e) => setCashierEmail(e.target.value)} required />
        </div>
        <div>
          <label>Contraseña del Cajero:</label>
          <input type="password" value={cashierPassword} onChange={(e) => setCashierPassword(e.target.value)} required />
        </div>
        <div>
          <label>Productos a Activar:</label>
          <div>
            <label>
              <input type="checkbox" value="fast_food" onChange={handleProductChange} /> Fast Food
            </label>
            <label>
              <input type="checkbox" value="full_service" onChange={handleProductChange} /> Full Service
            </label>
            <label>
              <input type="checkbox" value="encuestas" onChange={handleProductChange} /> Encuestas
            </label>
            <label>
              <input type="checkbox" value="cupones" onChange={handleProductChange} /> Cupones
            </label>
            <label>
              <input type="checkbox" value="raspaditas" onChange={handleProductChange} /> Raspaditas
            </label>
            <label>
              <input type="checkbox" value="gift_card" onChange={handleProductChange} /> Gift Card
            </label>
          </div>
        </div>
        <button type="button" onClick={openTermsModal}>Leer Términos y Condiciones</button>
        <button type="submit" disabled={isRegistering}>Registrarse</button>
        {mensaje && <p>{mensaje}</p>}
      </form>
      <button onClick={() => navigate('/')}>Regresar al Inicio</button> {/* Nuevo botón agregado */}
      <Modal isOpen={isTermsModalOpen} onRequestClose={() => setIsTermsModalOpen(false)} contentLabel="Términos y Condiciones">
        {termsContent}
      </Modal>
      <Modal isOpen={isRegistrationSuccessful} onRequestClose={() => setIsRegistrationSuccessful(false)} contentLabel="Registro Exitoso">
        <div className="success-modal-content">
          <div className="message">
            <h2>¡Registro Exitoso! 😀</h2>
            <p>¡Tu marca ha sido registrada exitosamente!</p>
            <button onClick={() => navigate('/')}>Volver a Inicio</button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isWelcomeModalOpen} onRequestClose={() => setIsWelcomeModalOpen(false)} contentLabel="Bienvenido Socio">
        {welcomeContent}
      </Modal>
    </div>
  );
};

export default NuevasMarcas;
