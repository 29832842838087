// GenericLogin.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';

const GenericLogin = ({ clientName, redirectPath }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);

  const handleLogin = async (event) => {
    event.preventDefault();
    // Aquí iría la lógica específica para el cliente, como parámetros adicionales en la solicitud fetch
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
    try {
      const response = await fetch(`${backendUrl}/login/${clientName}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        setUser(data.user);
        navigate(redirectPath); // Navegar al dashboard específico del cliente
      } else {
        setLoginError(data.message || `Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setLoginError(`Error: ${error.message}`);
    }
  };

  return (
    <form className="auth" onSubmit={handleLogin}>
      <h2>Iniciar Sesión - {clientName}</h2>
      <input
        type="email"
        placeholder="Correo Electrónico"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Contraseña"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <button type="submit">Iniciar Sesión</button>
      {loginError && <p className="error">{loginError}</p>}
    </form>
  );
};

export default GenericLogin;
