import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';

const EncuestasEmpanadas = () => {
  const [survey, setSurvey] = useState({
    title: '',
    branches: [], // Branch IDs will be stored here
    duration: {
      start: new Date(),
      end: new Date()
    },
    questions: [],
    status: 'active'
  });
  const [indefinite, setIndefinite] = useState(false);
  const [questionText, setQuestionText] = useState('');
  const [questionType, setQuestionType] = useState('single_choice');

  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSurvey({ ...survey, [name]: value });
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setSurvey({ ...survey, duration: { start, end } });
  };

  const handleIndefiniteChange = () => {
    setIndefinite(!indefinite);
  };

  const handleAddQuestion = () => {
    if (questionText.trim()) {
      setSurvey({
        ...survey,
        questions: [...survey.questions, { question_text: questionText, question_type: questionType }],
      });
      setQuestionText('');
    }
  };

  const handleSubmit = async () => {
    if (indefinite) {
      survey.duration.start = null;
      survey.duration.end = null;
    }

    try {
      // Create survey
      const { data: newSurvey } = await axios.post(`${backendUrl}/surveys`, {
        title: survey.title,
        branches: survey.branches,
        startDate: survey.duration.start,
        endDate: survey.duration.end,
        status: survey.status
      });

      // Add questions to the survey
      for (const question of survey.questions) {
        await axios.post(`${backendUrl}/surveys/${newSurvey.id}/questions`, {
          questions: [question]
        });
      }

      alert('Encuesta creada con éxito!');
      // Reset state or handle success (e.g., redirection)
    } catch (error) {
      console.error('Error al crear la encuesta:', error);
      alert('Error al crear la encuesta. Por favor, intente de nuevo.');
    }
  };

  const backToMenuStyle = {
    display: 'inline-block',
    margin: '10px 0',
    padding: '5px 10px',
    backgroundColor: '#f0f0f0', // Adjust the color to match your design
    color: '#333',
    textDecoration: 'none',
    borderRadius: '5px',
    fontWeight: 'bold',
  };

  return (
    <div>
      <Link to="/" style={backToMenuStyle}>Regresar al Menú</Link>
      <h2>Nueva Encuesta</h2>
      <input
        type="text"
        name="title"
        value={survey.title}
        onChange={handleInputChange}
        placeholder="Título de la encuesta"
      />
      {/* Branch selection logic will go here */}
      <DatePicker
        selectsRange
        startDate={survey.duration.start}
        endDate={survey.duration.end}
        onChange={handleDateChange}
        isClearable={true}
      />
      <label>
        Duración indefinida:
        <input type="checkbox" checked={indefinite} onChange={handleIndefiniteChange} />
      </label>
      <input
        type="text"
        value={questionText}
        onChange={(e) => setQuestionText(e.target.value)}
        placeholder="Texto de la pregunta"
      />
      <select value={questionType} onChange={(e) => setQuestionType(e.target.value)}>
        <option value="single_choice">Single Choice</option>
        <option value="multiple_choice">Multiple Choice</option>
        <option value="open_ended">Open Ended</option>
      </select>
      <button onClick={handleAddQuestion}>Agregar Pregunta</button>
      <div>
        {survey.questions.map((q, index) => (
          <div key={index}>{q.question_text} - {q.question_type}</div>
        ))}
      </div>
      <button onClick={handleSubmit}>Guardar Encuesta</button>
    </div>
  );
};

export default EncuestasEmpanadas;
