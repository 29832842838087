import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

const UploadQRCodeMenuDeLena = () => {
  const [uploadStatus, setUploadStatus] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      const formData = new FormData();
      formData.append('qrMenu', file);
      
      setUploadStatus('Subiendo código QR...');
      
      axios.post(`${backendUrl}/upload_qr_menu_DeLena`, formData)
        .then(response => {
          setUploadStatus('Código QR subido con éxito.');
        })
        .catch(error => {
          console.error('Error al subir el código QR:', error);
          setUploadStatus('Error al subir el código QR.');
        });
    } else {
      alert("Por favor, carga una imagen del código QR.");
      setUploadStatus('');
    }
  }, [backendUrl]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false
  });

  return (
    <div>
      <div {...getRootProps()} style={{ border: '2px dashed black', padding: '20px', textAlign: 'center' }}>
        <input {...getInputProps()} />
        <p>Arrastra y suelta aquí la imagen del código del arte para el menu QR, o haz clic para seleccionarla.</p>
      </div>
      {uploadStatus && (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <p>{uploadStatus}</p>
        </div>
      )}
    </div>
  );
};

export default UploadQRCodeMenuDeLena;
