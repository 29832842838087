import React, { createContext, useState, useEffect, useCallback } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [client, setClient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  const setAuthToken = (token) => {
    localStorage.setItem('authToken', token);
  };

  const getAuthToken = () => {
    return localStorage.getItem('authToken');
  };

  const setClientToStorage = (clientName) => {
    localStorage.setItem('client', clientName);
  };

  const getClientFromStorage = () => {
    return localStorage.getItem('client');
  };

  const login = async (email, password, clientName) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${backendUrl}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, client: clientName }),
      });

      const data = await response.json();
      if (response.ok) {
        setAuthToken(data.token);
        setUser(data.user);
        setClient(clientName);
        setClientToStorage(clientName);
        return { success: true, user: data.user };
      } else {
        console.error(`Error during login: ${data.message}`);
        return { success: false, message: data.message };
      }
    } catch (error) {
      console.error(`Login error: ${error.message}`);
      return { success: false, message: error.message };
    } finally {
      setIsLoading(false);
    }
  };

  const logout = useCallback(() => {
    setIsLoading(true);
    localStorage.removeItem('authToken');
    localStorage.removeItem('client');
    setUser(null);
    setClient(null);
    setIsLoading(false);
  }, []);

  const checkAuthStatus = useCallback(async () => {
    const token = getAuthToken();
    const storedClient = getClientFromStorage();
    if (token) {
      setIsLoading(true);
      try {
        const response = await fetch(`${backendUrl}/validate-token`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (response.ok) {
          setUser(data.user);
          setClient(storedClient);
        } else {
          console.error(`Token validation error: ${data.message}`);
          logout();
        }
      } catch (error) {
        console.error(`Token validation error: ${error.message}`);
        logout();
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [backendUrl, logout]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const getHomeRoute = useCallback(() => {
    switch(client) {
      case 'Empanadas_del_paco':
        return '/home-empanadas';
      case 'el_manglar':
        return '/home-el_manglar';
      case 'la_costenita':
        return '/home-la_costenita';
      case 'encuestadora_xy':
        return '/home-encuestadora_xy';
      case 'delena':
        return '/home-delena';
      case 'foodlove':
        return '/home-foodlove';
      case 'eddys_bbq':
        return '/home-eddysbbq';
      default:
        return '/';
    }
  }, [client]);

  return (
    <AuthContext.Provider value={{ 
      user, 
      setUser, 
      client, 
      setClient, 
      isLoading, 
      login, 
      logout,
      checkAuthStatus,
      getHomeRoute
    }}>
      {children}
    </AuthContext.Provider>
  );
};