import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import './EncuestasEncuestadoraXY.css';
import EditSurveyModal from './EditSurveyModal';
import CreateSurveyModal from './CreateSurveyModal';

const EncuestasEncuestadoraXY = () => {
  const [survey, setSurvey] = useState({
    title: '',
    branches: [],
    duration: {
      start: new Date(),
      end: new Date()
    },
    questions: [],
    status: 'active',
    questionText: '',
    questionType: 'multiple_choice',
    optionText: ''
  });
  const [editingSurvey, setEditingSurvey] = useState(null);
  const [surveys, setSurveys] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  useEffect(() => {
    fetchSurveys();
  }, []);

  const fetchSurveys = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/encuestasxy`);
      setSurveys(response.data);
      console.log('Encuestas cargadas:', response.data);
    } catch (error) {
      console.error('Error al cargar las encuestas:', error);
    }
  };

  const fetchQuestions = async (encuestaId) => {
    try {
      const response = await axios.get(`${backendUrl}/api/encuestasxy/${encuestaId}/preguntas`);
      console.log('Preguntas obtenidas para edición:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error al cargar las preguntas:', error);
      return [];
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSurvey({ ...survey, [name]: value });
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setSurvey({ ...survey, duration: { start, end } });
  };

  const handleAddOption = (questionIndex) => {
    const updatedQuestions = survey.questions.map((q, index) => {
      if (index === questionIndex) {
        return { ...q, opciones: [...(q.opciones || []), survey.optionText] };
      }
      return q;
    });
    setSurvey({ ...survey, questions: updatedQuestions, optionText: '' });
  };

  const handleUpdateOption = (questionIndex, optionIndex, value) => {
    const updatedQuestions = survey.questions.map((q, index) => {
      if (index === questionIndex) {
        const updatedOptions = q.opciones.map((op, idx) => {
          if (idx === optionIndex) {
            return value;
          }
          return op;
        });
        return { ...q, opciones: updatedOptions };
      }
      return q;
    });
    setSurvey({ ...survey, questions: updatedQuestions });
  };

  const handleAddQuestion = () => {
    if (survey.questionText.trim()) {
      setSurvey({
        ...survey,
        questions: [...survey.questions, { question_text: survey.questionText, question_type: survey.questionType, opciones: [] }],
        questionText: '',
        questionType: 'multiple_choice'
      });
    }
  };

  const handleUpdateQuestion = (questionIndex, value) => {
    const updatedQuestions = survey.questions.map((q, index) => {
      if (index === questionIndex) {
        return { ...q, question_text: value };
      }
      return q;
    });
    setSurvey({ ...survey, questions: updatedQuestions });
  };

  const validateSurveyData = () => {
    if (!survey.title?.trim()) {
      alert('El título de la encuesta es obligatorio.');
      return false;
    }
    if (!survey.duration.start || !survey.duration.end) {
      alert('La duración de la encuesta es obligatoria.');
      return false;
    }
    for (const question of survey.questions) {
      if (!question.question_text?.trim()) {
        alert('El texto de la pregunta es obligatorio.');
        return false;
      }
      if (!question.question_type?.trim()) {
        alert('El tipo de pregunta es obligatorio.');
        return false;
      }
      if (question.question_type === 'multiple_choice' && (!question.opciones || question.opciones.length === 0)) {
        alert('Las opciones de respuesta son obligatorias para preguntas de respuesta múltiple.');
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateSurveyData()) return;

    try {
      if (editingSurvey) {
        console.log('Datos de la encuesta antes de enviar:', {
          title: survey.title,
          branches: survey.branches,
          startDate: survey.duration.start.toISOString(),
          endDate: survey.duration.end.toISOString(),
          status: survey.status
        });

        await axios.put(`${backendUrl}/api/encuestasxy/${editingSurvey}`, {
          title: survey.title,
          branches: survey.branches,
          startDate: survey.duration.start.toISOString(),
          endDate: survey.duration.end.toISOString(),
          status: survey.status
        });

        for (const question of survey.questions) {
          console.log('Datos de la pregunta antes de enviar:', {
            question_text: question.question_text,
            question_type: question.question_type,
            opciones: question.opciones
          });

          if (question.id) {
            await axios.put(`${backendUrl}/api/encuestasxy/${editingSurvey}/preguntas/${question.id}`, {
              question_text: question.question_text,
              question_type: question.question_type,
              opciones: question.opciones
            });
          } else {
            await axios.post(`${backendUrl}/api/encuestasxy/${editingSurvey}/preguntas`, {
              question_text: question.question_text,
              question_type: question.question_type,
              opciones: question.opciones
            });
          }
        }
        alert('Encuesta actualizada con éxito!');
      } else {
        const { data: newSurvey } = await axios.post(`${backendUrl}/api/encuestasxy`, {
          title: survey.title,
          branches: survey.branches,
          startDate: survey.duration.start.toISOString(),
          endDate: survey.duration.end.toISOString(),
          status: survey.status
        });

        for (const question of survey.questions) {
          await axios.post(`${backendUrl}/api/encuestasxy/${newSurvey.encuestaId}/preguntas`, {
            question_text: question.question_text,
            question_type: question.question_type,
            opciones: question.opciones
          });
        }
        alert('Encuesta creada con éxito!');
      }
      setEditingSurvey(null);
      setShowModal(false);
      setShowCreateModal(false);
      fetchSurveys();
    } catch (error) {
      console.error('Error al crear/actualizar la encuesta:', error);
      alert('Error al crear/actualizar la encuesta. Por favor, intente de nuevo.');
    }
  };

  const toggleSurveyStatus = async (id, currentStatus) => {
    try {
      await axios.patch(`${backendUrl}/api/encuestasxy/${id}`, { activa: !currentStatus });
      fetchSurveys();
    } catch (error) {
      console.error('Error al actualizar la encuesta:', error);
    }
  };

  const viewStatistics = (id) => {
    navigate(`/encuestasxy/${id}/estadisticas`);
  };

  const deleteSurvey = async (id) => {
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar esta encuesta?');
    if (confirmDelete) {
      try {
        await axios.delete(`${backendUrl}/api/encuestasxy/${id}`);
        alert('Encuesta eliminada con éxito!');
        fetchSurveys();
      } catch (error) {
        console.error('Error al eliminar la encuesta:', error);
        alert('Error al eliminar la encuesta. Por favor, intente de nuevo.');
      }
    }
  };

  const editSurvey = async (survey) => {
    const questions = await fetchQuestions(survey.id);
    console.log('Preguntas obtenidas para edición:', questions);
    setSurvey({
      title: survey.titulo || '',
      branches: survey.branches || [],
      duration: {
        start: survey.start_date ? new Date(survey.start_date) : new Date(),
        end: survey.end_date ? new Date(survey.end_date) : new Date()
      },
      questions: questions.map(q => ({
        ...q,
        question_text: q.texto,
        question_type: q.tipo,
        opciones: q.opciones || []
      })),
      status: survey.activa ? 'active' : 'inactive',
      questionText: '',
      questionType: 'multiple_choice',
      optionText: ''
    });
    setEditingSurvey(survey.id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeCreateModal = () => {
    setShowCreateModal(false);
  };

  const downloadExcel = async (surveyId) => {
    try {
      const response = await axios.get(`${backendUrl}/api/encuestasxy/${surveyId}/respuestas`);
      const respuestas = response.data;

      const ws = XLSX.utils.json_to_sheet(respuestas);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Respuestas');

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, `informe_encuesta_${surveyId}.xlsx`);
    } catch (error) {
      console.error('Error al descargar el informe:', error);
      alert('Error al descargar el informe. Por favor, intente de nuevo.');
    }
  };

  const handleNavigate = () => {
    navigate('/');
  };

  return (
    <div className="container">
      <div className="header">
        <h2>{editingSurvey ? 'Editar Encuesta' : 'Nueva Encuesta'}</h2>
        <button onClick={handleNavigate} className="button">Regresar al Menú</button>
      </div>
      <button className="button" onClick={() => setShowCreateModal(true)}>Nueva Encuesta</button>
      {showCreateModal && (
        <CreateSurveyModal
          survey={survey}
          setSurvey={setSurvey}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          handleAddOption={handleAddOption}
          handleUpdateOption={handleUpdateOption}
          handleAddQuestion={handleAddQuestion}
          handleUpdateQuestion={handleUpdateQuestion}
          handleSubmit={handleSubmit}
          closeModal={closeCreateModal}
          backendUrl={backendUrl} // Pasa la URL del backend como prop
        />
      )}
      {showModal && (
        <EditSurveyModal
          survey={survey}
          setSurvey={setSurvey}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          handleAddOption={handleAddOption}
          handleUpdateOption={handleUpdateOption}
          handleAddQuestion={handleAddQuestion}
          handleUpdateQuestion={handleUpdateQuestion}
          handleSubmit={handleSubmit}
          closeModal={closeModal}
        />
      )}
      <h2>Encuestas Existentes</h2>
      <ul className="survey-list">
        {surveys.map(s => (
          <li key={s.id}>
            <h3>{s.titulo}</h3>
            <p>Estado: {s.activa ? 'Activa' : 'Inactiva'}</p>
            <p>Respuestas: {s.response_count}</p>
            <button className="button" onClick={() => editSurvey(s)}>Editar Encuesta</button>
            <button className="button" onClick={() => toggleSurveyStatus(s.id, s.activa)}>
              {s.activa ? 'Desactivar' : 'Activar'}
            </button>
            <button className="button" onClick={() => viewStatistics(s.id)}>Ver estadísticas</button>
            <button className="button" onClick={() => deleteSurvey(s.id)}>Eliminar Encuesta</button>
            <button className="button" onClick={() => downloadExcel(s.id)}>Descargar Informe</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EncuestasEncuestadoraXY;
