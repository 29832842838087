import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import logo from './logo-empanadasdepaco.png';
import './HomeEmpanadas.css';
import background from './fondo_empanadas.jpg';

const HomeEmpanadas = () => {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showConfig, setShowConfig] = useState(false);
  const configRef = useRef(null);

  const handleNavigation = (path) => {
    navigate(path);
    setShowConfig(false);
  };

  const handleLogout = () => {
    setUser(null);
    navigate('/login/Empanadas_del_paco');
  };

  useEffect(() => {
    if (!user) {
      navigate('/login/Empanadas_del_paco');
    } else if (user.client !== 'Empanadas_del_paco') {
      navigate('/main');
    }
  }, [user, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (configRef.current && !configRef.current.contains(event.target)) {
        setShowConfig(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [configRef]);

  return (
    <div className="home-empanadas" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}>
      <div className="top-bar">
        <img src={logo} alt="Logo Empanadas del Paco" className="logo" />
        <div className="top-right-container">
          <span className="user-greeting">Bienvenido, {user?.name}</span>
          {user && (user.role === 'superadmin' || user.role === 'admin') && (
            <button onClick={() => setShowConfig(!showConfig)} className="config-button">
              CONFIG
            </button>
          )}
          {showConfig && (
            <div className="config-dropdown" ref={configRef}>
              <button onClick={() => handleNavigation('/settings')}>Configuración de sistema</button>
              <button onClick={handleLogout}>Cerrar sesión</button>
              {(user.role === 'admin' || user.role === 'superadmin') && (
                <>
                  <button onClick={() => handleNavigation('/UploadComponentEmpanadas')}>Cargar menú</button>
                  {user.role === 'superadmin' && (
                    <button onClick={() => handleNavigation('/UploadQRCodeMenuEmpanadas')}>Cargar QR Menú</button>
                  )}
                  <button onClick={() => handleNavigation('/DownloadLatestQRMenu')}>Descargar QR nuevos registros</button>
                  <button onClick={() => handleNavigation('/pagos')}>Pagos</button>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {user && user.role === 'superadmin' && (
        <>
          <button onClick={() => handleNavigation('/ValidadorCliente-LasEmpanadasDelPaco')}>Ir a Validación de nuevos socios</button>
          <button onClick={() => handleNavigation('/coupon')}>Cupones</button>
          <button onClick={() => handleNavigation('/iamarketing')}>IA Marketing</button>
          <button onClick={() => handleNavigation('/raspaditas')}>Raspaditas</button>
          <button onClick={() => handleNavigation('/restaurantes')}>IA Writer</button>
          <button onClick={() => handleNavigation('/integraciones-restaurante')}>Integraciones Restaurant.pe</button>
          <button onClick={() => handleNavigation('/gift-cards')}>Gift Cards</button>
          <button onClick={() => handleNavigation('/EncuestasApp')}>Encuestas</button>
          <button onClick={() => handleNavigation('/RespuestasEncuesta')}>Respuesta encuestas</button>
        </>
      )}

      {user && user.role === 'admin' && user.client === 'Empanadas_del_paco' && (
        <>
          <button onClick={() => handleNavigation('/ValidadorCliente-LasEmpanadasDelPaco')}>Ir a Validación de nuevos socios</button>
          <button onClick={() => handleNavigation('/coupon')}>Cupones</button>
          <button onClick={() => handleNavigation('/iamarketing')}>IA Marketing</button>
          <button onClick={() => handleNavigation('/raspaditas')}>Raspaditas</button>
          <button onClick={() => handleNavigation('/restaurantes')}>IA Writer</button>
          <button onClick={() => handleNavigation('/integraciones-restaurante')}>Integraciones Restaurant.pe</button>
          <button onClick={() => handleNavigation('/gift-cards')}>Gift Cards</button>
          <button onClick={() => handleNavigation('/EncuestasApp')}>Encuestas</button>
          <button onClick={() => handleNavigation('/RespuestasEncuesta')}>Respuesta encuestas</button>
        </>
      )}

      {user && user.role === 'employee' && user.client === 'Empanadas_del_paco' && (
        <>
          <button onClick={() => handleNavigation('/ValidadorCliente-LasEmpanadasDelPaco')}>Ir a Validación de nuevos socios</button>
          <button onClick={() => handleNavigation('/LasEmpanadasDelPaco_Menu')}>Ver Menú</button>
          <button onClick={handleLogout}>Cerrar sesión</button>
        </>
      )}

      {user && user.role === 'client' && user.client === 'Empanadas_del_paco' && (
        <>
          <button onClick={() => handleNavigation('/coupon')}>Cupones</button>
          <button onClick={() => handleNavigation('/RespuestasEncuesta')}>Respuesta encuestas</button>
          <button onClick={() => handleNavigation('/LasEmpanadasDelPaco_Menu')}>Ver Menú</button>
          <button onClick={handleLogout}>Cerrar sesión</button>
          <button onClick={() => handleNavigation('/Unsubscribe')}>Dejar de ser miembro</button>
        </>
      )}

      {!user && (
        <>
          <button onClick={() => handleNavigation('/tarjeta-de-contacto')}>Quejas y sugerencias</button>
          <button onClick={() => handleNavigation('/LasEmpanadasDelPaco_Menu')}>Ver Menú</button>
          <button onClick={handleLogout}>Cerrar sesión</button>
          <button onClick={() => handleNavigation('/Unsubscribe')}>Dejar de ser miembro</button>
        </>
      )}

      {!user && (
        <p>Descubre nuestras empanadas y disfruta de promociones exclusivas.</p>
      )}
    </div>
  );
};



export default HomeEmpanadas;
