import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => (
  <div className="min-h-screen flex flex-col items-center bg-gray-100 p-4">
    <header className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6 text-center">
      <div className="flex justify-between items-center mb-6">
        <Link to="/main" className="btn btn-outline-primary">
          Marcas
        </Link>
        <img src={logo} alt="Logo" className="h-20" />
      </div>
      <h1 className="text-2xl font-bold text-gray-800 mb-4">
        Bienvenidos a la Cuponera de Siempre con Descuentos!
      </h1>
      <nav className="flex flex-col md:flex-row justify-center items-center gap-4 mt-6">
        <Link to="/promociones" className="btn btn-success w-full md:w-auto">
          Promociones disponibles
        </Link>
        <Link to="/nuevas-marcas" className="btn btn-warning w-full md:w-auto">
          REGISTRA TU MARCA
        </Link>
      </nav>
    </header>
  </div>
);

export default Home;
