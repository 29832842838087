import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import './HomeEncuestadoraXY.css';

const HomeEncuestadoraXY = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showConfig, setShowConfig] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const configRef = useRef(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
  const pdfProxyUrl = `${backendUrl}/download_pdf`;

  const handleNavigation = (path) => {
    navigate(path);
    setShowConfig(false);
  };

  const handleShowPdfModal = () => {
    setShowPdfModal(true);
  };

  const handleClosePdfModal = () => {
    setShowPdfModal(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login/encuestadora_xy');
  };

  useEffect(() => {
    if (!user) {
      navigate('/login/encuestadora_xy');
    } else if (user.client !== 'encuestadora_xy') {
      navigate('/main');
    }
  }, [user, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (configRef.current && !configRef.current.contains(event.target)) {
        setShowConfig(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [configRef]);

  return (
    <div className="home-encuestadora-xy">
      <div className="top-bar">
        <div className="top-right-container">
          <span className="user-greeting">Bienvenido, {user?.name}</span>
          {user && (user.role === 'superadmin' || user.role === 'admin') && (
            <button onClick={() => setShowConfig(!showConfig)} className="config-button">
              CONFIG
            </button>
          )}
          {showConfig && (
            <div className="config-dropdown" ref={configRef}>
              <button onClick={() => handleNavigation('/settings')}>Configuración de sistema</button>
              <button onClick={handleLogout}>Cerrar sesión</button>
            </div>
          )}
        </div>
      </div>

      {user && user.role === 'superadmin' && (
        <>
          <button onClick={() => handleNavigation('/EncuestasEncuestadoraXY')}>Ir a Encuestas disponibles</button>
          <button onClick={() => handleNavigation('/RespuestasEncuestaXY')}>Ir a Respuestas de Encuestas</button>
          <button onClick={handleShowPdfModal}>Qr Inteligente nuevos registros</button>
        </>
      )}

      {user && user.role === 'admin' && user.client === 'encuestadora_xy' && (
        <>
          <button onClick={() => handleNavigation('/EncuestasEncuestadoraXY')}>Ir a Panel administrador de Encuestas</button>
          <button onClick={() => handleNavigation('/RespuestasEncuestaXY')}>Ir a Encuestas disponibles</button>
          <button onClick={handleShowPdfModal}>Qr Inteligente nuevos registros</button>
        </>
      )}

      {user && user.role === 'client' && user.client === 'encuestadora_xy' && (
        <button onClick={() => handleNavigation('/RespuestasEncuestaXY')}>Ir a Encuestas disponibles</button>
      )}

      <button onClick={handleLogout}>Cerrar sesión</button>

      {showPdfModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleClosePdfModal}>&times;</span>
            <iframe
              src={pdfProxyUrl}
              title="PDF Document"
              width="100%"
              height="600px"
              style={{ border: 'none' }}
            ></iframe>
            <button onClick={handleClosePdfModal} className="button">Regresar al Menú</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeEncuestadoraXY;
