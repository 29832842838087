import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import logoDeLena from './logo-DeLena.png';
import logoSiempreConDescuentos from '../../assets/logo.png'; // Ruta actualizada a 'src/assets'
import './LoginDeLena.css';

const LoginDeLena = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { client } = useParams();

  useEffect(() => {
    console.log("Client received:", client); // Depuración para ver qué cliente se recibe
  }, [client]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password, client);
      navigate(`/home-${client}`);
    } catch (error) {
      setLoginError(`Error: ${error.message}`);
    }
  };

  const handleForgotPassword = () => {
    navigate(`/forgot-password/${client}`); // Incluir el cliente en la ruta de recuperación de contraseña
  };

  return (
    <div className="login-container">
      <form className="auth" onSubmit={handleLogin}>
        <img src={logoDeLena} alt="Logo De Leña" />
        <h2>🍕 Bienvenido socio de De Leña! 🔥</h2>
        <input type="email" name="email" placeholder="Tu correo 📧" value={email} onChange={e => setEmail(e.target.value)} required />
        <input type="password" name="password" placeholder="Contraseña 🔑" value={password} onChange={e => setPassword(e.target.value)} required />
        <button type="submit">Iniciar sesión 😊</button>
        {loginError && <p>{loginError}</p>}
        <button type="button" onClick={handleForgotPassword}>Olvide la contraseña 🥲</button>
        <button type="button" onClick={() => navigate(`/register-delena`)}>Registrarme como nuevo socio 🫶</button>
      </form>
      <img
        src={logoSiempreConDescuentos}
        alt="Logo Siempre con Descuentos"
        className="logo-siempre-con-descuentos"
        onClick={() => navigate('/')}
      />
    </div>
  );
};

export default LoginDeLena;
