import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import logoDeLena from './logo-DeLena.png';
import fastFoodLogo from './fast-food-logo.png';
import drinksLogo from './drinks-logo.png';
import dessertsLogo from './desserts-logo.png';
import userIcon from './user-icon.png'; // Asegúrate de tener la ruta correcta de la imagen
import Modal from 'react-modal';
import './HomeDeLena.css';

const HomeDeLena = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showConfig, setShowConfig] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const configRef = useRef(null);

  const handleNavigation = (path) => {
    navigate(path);
    setShowConfig(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login/delena');
  };

  useEffect(() => {
    if (!user) {
      navigate('/login/delena');
    } else if (user.client !== 'delena') {
      navigate('/login/delena');
    }
  }, [user, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (configRef.current && !configRef.current.contains(event.target)) {
        setShowConfig(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [configRef]);

  useEffect(() => {
    const handlePopState = () => {
      navigate('/login/delena');
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const menuOptions = [
    {
      name: 'Fast Food',
      logo: fastFoodLogo,
      functionalities: [
        { name: 'Ver el Menú', path: '/ver-menu-delena' },
        { name: 'Cargar menú', path: '/UploadComponentDeLena' },
        { name: 'Descargar QR nuevos registros', path: '/DownloadLatestQRMenuDeLena' },
        { name: 'Quejas y sugerencias', path: '/tarjeta-de-contacto-delena' }
      ]
    },
    {
      name: 'Drinks',
      logo: drinksLogo,
      functionalities: [
        { name: 'IA', path: '/drinks/optionA' },
        { name: 'MKT', path: '/drinks/optionB' },
        { name: 'INTEGRACION RESTAURANTES', path: '/drinks/optionC' }
      ]
    },
    {
      name: 'Desserts',
      logo: dessertsLogo,
      functionalities: [
        { name: 'BUSINESS INTELLIGENCE', path: '/desserts/optionX' },
        { name: 'BLOG', path: '/desserts/optionY' },
        { name: 'RASPADITAS', path: '/desserts/optionZ' }
      ]
    }
  ];

  const handleOptionClick = (option) => {
    if (option.name === 'Drinks' || option.name === 'Desserts') {
      setIsPopupOpen(true);
    } else {
      setSelectedOption(option.name);
    }
  };

  const selectedMenuOption = menuOptions.find(option => option.name === selectedOption);

  return (
    <div className="home-de-lena">
      <div className="top-bar">
        <img src={logoDeLena} alt="Logo De Leña" className="logo" />
        <div className="top-right-container">
          <img src={userIcon} alt="User Icon" className="user-icon" />
          <div className="user-info">
            <span className="user-greeting">Bienvenido, {user?.name}</span>
            {user && (user.role === 'superadmin' || user.role === 'admin') && (
              <button onClick={() => setShowConfig(!showConfig)} className="config-button">
                CONFIG
              </button>
            )}
          </div>
          {showConfig && (
            <div className="config-dropdown" ref={configRef}>
              {user.role === 'superadmin' && (
                <button onClick={() => handleNavigation('/settings')}>Configuración de sistema</button>
              )}
              <button onClick={handleLogout}>Cerrar sesión</button>
            </div>
          )}
        </div>
      </div>

      <div className="interactive-menu">
        <div className="menu-options">
          {menuOptions.map(option => (
            <div key={option.name} className="menu-option" onClick={() => handleOptionClick(option)}>
              <img src={option.logo} alt={`${option.name} logo`} />
              <p>{option.name}</p>
            </div>
          ))}
        </div>
        {selectedMenuOption && (
          <div className="menu-functionalities">
            <h2>{selectedMenuOption.name} Functionalities</h2>
            <ul>
              {selectedMenuOption.functionalities.map((func, index) => (
                <li key={index} onClick={() => handleNavigation(func.path)}>{func.name}</li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <button className="logout-button" onClick={handleLogout}>Cerrar sesión</button>
      <button className="unsubscribe-button" onClick={() => handleNavigation('/Unsubscribe_DeLena')}>Dejar de ser miembro</button>

      {!user && (
        <p>Descubre nuestros productos y disfruta de promociones exclusivas.</p>
      )}

      <Modal
        isOpen={isPopupOpen}
        onRequestClose={() => setIsPopupOpen(false)}
        contentLabel="Contact Sales Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Contacta con el Área de Ventas</h2>
        <p>Para ver las funcionalidades de Drinks y Desserts, por favor comunícate con el área de ventas para poder agregarlos a tu plan.</p>
        <button onClick={() => setIsPopupOpen(false)}>Cerrar</button>
      </Modal>
    </div>
  );
};

export default HomeDeLena;
