import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import logo from './logo-empanadasdepaco.png';
import respuestaEncuestaCliente from './RespuestasEncuestaCliente.png'; // Ajusta la ruta si es necesario


const EncuestasApp = () => {
    const { token } = useContext(AuthContext);
    const [titulo, setTitulo] = useState('');
    const [preguntas, setPreguntas] = useState([{ texto: '', tipo: 'texto', opciones: [''] }]);
    const [surveys, setSurveys] = useState([]);
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const [responses, setResponses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

    useEffect(() => {
        fetchSurveys();
    }, []);

    const fetchSurveys = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/encuestas`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setSurveys(response.data); // Assuming response.data includes each survey with its response_count
        } catch (error) {
            console.error('Error fetching surveys:', error);
            setError('Error al cargar las encuestas');
        } finally {
            setLoading(false);
        }
    };



    const fetchResponsesCount = async (surveyId) => {
        try {
            const response = await axios.get(`${backendUrl}/api/encuestas/${surveyId}/respuestas/count`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setSurveys(prevSurveys => prevSurveys.map(survey => survey.id === surveyId ? {
                ...survey,
                cantidad_respuestas: response.data.count
            } : survey));
        } catch (error) {
            console.error(`Error fetching responses count for survey ${surveyId}:`, error);
        }
    };

    const descargarQR = () => {
        const link = document.createElement('a');
        link.href = respuestaEncuestaCliente;
        link.download = 'RespuestaEncuestaCliente.png'; // Esto hará que el navegador descargue el archivo en vez de navegar a la URL
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      




    const fetchResponses = async (surveyId) => {
        try {
            const response = await axios.get(`${backendUrl}/api/encuestas/${surveyId}/responses`);
            setResponses(response.data);
        } catch (error) {
            console.error(`Error fetching responses for survey ${surveyId}:`, error);
        }
    };

    const handleSurveySelect = (survey) => {
        setSelectedSurvey(survey);
        fetchResponses(survey.id);
    };

    const agregarPregunta = () => {
        setPreguntas([...preguntas, { texto: '', tipo: 'texto', opciones: [] }]);
    };

    const handlePreguntaChange = (index, campo, valor) => {
        const nuevasPreguntas = preguntas.map((pregunta, idx) => {
            if (idx === index) {
                return { ...pregunta, [campo]: valor };
            }
            return pregunta;
        });
        setPreguntas(nuevasPreguntas);
    };

    const agregarOpcion = (indexPregunta) => {
        const nuevasPreguntas = preguntas.map((pregunta, idx) => {
            if (idx === indexPregunta && pregunta.tipo === 'opcion') {
                return { ...pregunta, opciones: [...pregunta.opciones, ''] };
            }
            return pregunta;
        });
        setPreguntas(nuevasPreguntas);
    };

    const handleOpcionChange = (indexPregunta, indexOpcion, valor) => {
        const nuevasPreguntas = preguntas.map((pregunta, idx) => {
            if (idx === indexPregunta && pregunta.tipo === 'opcion') {
                const nuevasOpciones = pregunta.opciones.map((opcion, idxOpcion) => {
                    if (idxOpcion === indexOpcion) {
                        return valor;
                    }
                    return opcion;
                });
                return { ...pregunta, opciones: nuevasOpciones };
            }
            return pregunta;
        });
        setPreguntas(nuevasPreguntas);
    };

    const guardarEncuesta = async () => {
        if (!titulo.trim() || preguntas.some(pregunta => !pregunta.texto.trim() || (pregunta.tipo === 'opcion' && pregunta.opciones.some(opcion => !opcion.trim())))) {
            alert('Por favor, asegúrate de completar todos los campos, incluyendo las opciones para las preguntas de selección múltiple.');
            return;
        }

        try {
            const { data } = await axios.post(`${backendUrl}/api/encuestas`, { titulo, preguntas, clienteId: '123' }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('Encuesta creada:', data);
            alert('Encuesta creada con éxito');
            fetchSurveys(); // Actualizar la lista de encuestas después de crear una
        } catch (error) {
            console.error('Error creando encuesta:', error);
            alert('Error al crear la encuesta');
        }
    };

    const toggleSurveyActive = async (surveyId, isActive) => {
        try {
            const response = await axios.patch(`${backendUrl}/api/encuestas/${surveyId}`, {
                activa: !isActive
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setSurveys(prevSurveys => prevSurveys.map(survey => {
                if (survey.id === surveyId) {
                    return {...survey, activa: !survey.activa};
                }
                return survey;
            }));
            alert('Estado de la encuesta actualizado');
        } catch (error) {
            console.error('Error al actualizar la encuesta:', error);
            alert('Error al actualizar la encuesta');
        }
    };

    return (
        <div>
      <Link to="/" className="logo-link">
        <img src={logo} alt="Logo Empanadas del Paco" className="card-logo" />
      </Link>
            <h1>Admin panel de Encuestas </h1>
            <div>
                <h2>Crear Encuesta</h2>
                <input value={titulo} onChange={e => setTitulo(e.target.value)} placeholder="Título de la encuesta" />
                {preguntas.map((pregunta, index) => (
                    <div key={index}>
                        <input value={pregunta.texto} onChange={e => handlePreguntaChange(index, 'texto', e.target.value)} placeholder="Pregunta" />
                        <select value={pregunta.tipo} onChange={e => handlePreguntaChange(index, 'tipo', e.target.value)}>
                            <option value="texto">Respuesta de Texto</option>
                            <option value="opcion">Respuesta Múltiple</option>
                        </select>
                        {pregunta.tipo === 'opcion' && pregunta.opciones.map((opcion, idxOpcion) => (
                            <div key={idxOpcion}>
                                <input value={opcion} onChange={e => handleOpcionChange(index, idxOpcion, e.target.value)} placeholder="Opción" />
                            </div>
                        ))}
                        {pregunta.tipo === 'opcion' && (
                            <button onClick={() => agregarOpcion(index)}>Agregar Opción</button>
                        )}
                    </div>
                ))}
                <button onClick={agregarPregunta}>Agregar Pregunta</button>
                <button onClick={guardarEncuesta}>Guardar Encuesta</button>
                <button onClick={descargarQR}>Descargar QR de Encuestas</button>
            </div>
            <div>
                <h2>Admin Module</h2>
                {loading ? <p>Cargando encuestas...</p> : (
                    <ul>
                        {surveys.length > 0 ? (
    surveys.map(survey => (
        <li key={survey.id}>
        {survey.titulo} - Activa: {survey.activa ? 'Sí' : 'No'} - Respuestas: {survey.response_count}
        <button onClick={() => toggleSurveyActive(survey.id, survey.activa)}>
            {survey.activa ? 'Desactivar' : 'Activar'}
        </button>
    </li>
    ))
) : (
    <li>No hay encuestas disponibles.</li>
)}
                    </ul>
                )}
                {error && <p>Error: {error}</p>}
                {selectedSurvey && responses.length > 0 && (
                    <div>
                        <h3>Selected Survey: {selectedSurvey.titulo}</h3>
                        <h4>Responses</h4>
                        <ul>
                            {responses.map(response => (
                                <li key={response.id}>{response.respuesta}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {selectedSurvey && responses.length === 0 && (
                    <p>No hay respuestas para esta encuesta.</p>
                )}
            </div>
        </div>
    );
};

export default EncuestasApp;
