import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ClienteFantasmaEmpanadasDelPaco = () => {
  const [pdfFileUrl, setPdfFileUrl] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  useEffect(() => {
    const fetchLatestMenuUrl = async () => {
      try {
        const response = await axios.get(`${backendUrl}/latest-menu-url`);
        setPdfFileUrl(response.data.pdfUrl); // Asumiendo que el backend envía un objeto con la propiedad 'pdfUrl'
      } catch (error) {
        console.error('Error fetching the latest menu URL:', error);
      }
    };

    fetchLatestMenuUrl();
  }, [backendUrl]);

  if (!pdfFileUrl) {
    return <div>Loading menu...</div>;
  }

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe
        src={pdfFileUrl}
        title="Empanadas Del Paco Menu"
        frameBorder="0"
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default ClienteFantasmaEmpanadasDelPaco;
