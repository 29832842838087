import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const DownloadLatestQRMenuLaCostenita = () => {
  const [qrMenuUrl, setQrMenuUrl] = useState('');
  const [loading, setLoading] = useState(false);
  
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  useEffect(() => {
    setLoading(true);
    axios.get(`${backendUrl}/latest-qr-menu-url-lacostenita`)
      .then(response => {
        setQrMenuUrl(response.data.qrMenuUrl);
      })
      .catch(error => {
        console.error('Error fetching latest QR menu URL:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [backendUrl]);

  const handleDownloadClick = (event) => {
    event.preventDefault(); // Evitar que el evento del clic cause una recarga de la página
    if (qrMenuUrl) {
      window.open(qrMenuUrl);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Link
        to="/"
        style={{
          display: 'inline-block',
          marginBottom: '20px',
          padding: '10px 20px',
          backgroundColor: '#f0ad4e',
          color: 'white',
          textDecoration: 'none',
          borderRadius: '5px',
          transition: 'background-color 0.3s ease'
        }}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#ec971f'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#f0ad4e'}
      >
        Regresar al Menú
      </Link>
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        {loading ? (
          <p>Cargando el último QR...</p>
        ) : qrMenuUrl ? (
          <div>
            <button
              onClick={handleDownloadClick}
              style={{
                padding: '10px 20px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease'
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
            >
              Descargar Último QR Menú
            </button>
          </div>
        ) : (
          <p>No se encontró un QR para descargar.</p>
        )}
      </div>
    </div>
  );
};

export default DownloadLatestQRMenuLaCostenita;
