import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import SignatureCanvas from 'react-signature-canvas';
import { AuthContext } from '../../auth/AuthContext';
import whatsappIcon from './whatsapp-icon.png'; // Verifica la ruta al icono de WhatsApp
import './RegisterEncuestadoraXY.css';

Modal.setAppElement('#root');

const RegisterEncuestadoraXY = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [identificationId, setIdentificationId] = useState('');
  const [registerStatus, setRegisterStatus] = useState('');
  const [signatureData, setSignatureData] = useState('');
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
  const [userCode, setUserCode] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const signatureRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhoneNumber = (phone) => /^0\d{9}$/.test(phone);

  const handleAcceptTerms = () => {
    if (signatureRef.current && !signatureRef.current.isEmpty()) {
      setSignatureData(signatureRef.current.toDataURL());
      setIsTermsModalOpen(false);
    } else {
      setRegisterStatus('Debe firmar antes de aceptar los términos y condiciones.');
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setRegisterStatus('El correo electrónico no es válido. Por favor, ingresa un correo válido.');
      return;
    }
    if (!validatePhoneNumber(phoneNumber)) {
      setRegisterStatus('El número de teléfono debe seguir el formato ecuatoriano (ej. 099365970).');
      return;
    }
    if (!signatureData) {
      setRegisterStatus('Debe aceptar los términos y condiciones y proporcionar una firma.');
      return;
    }

    setIsRegistering(true);
    const userData = {
      name,
      email,
      phoneNumber,
      password,
      consentTimestamp: new Date().toISOString(),
      signatureData,
      identificationId,
    };

    try {
      const response = await fetch(`${backendUrl}/register_encuestadoraxy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        const data = await response.json();
        setUserCode(data.user.user_code);
        setIsRegistrationSuccessful(true);
      } else {
        const errorData = await response.json();
        setRegisterStatus(errorData.message || `El servidor respondió con estado: ${response.status}`);
      }
    } catch (error) {
      setRegisterStatus('Error durante el registro. Por favor, inténtalo de nuevo más tarde.');
    } finally {
      setIsRegistering(false);
    }
  };

  const openTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const termsContent = (
    <div className="terms-content">
      <h2>Términos y Condiciones Encuestadora</h2>
      <p>Términos y Condiciones Generales para la Recopilación de Datos con Fines de Email Marketing y Estudios de Mercado en Ecuador</p>
      {/* Coloca aquí los términos y condiciones reales */}
      {/* Introducción */}
      <h3>Introducción</h3>
      <p>1.1. Propósito de los Términos: Estos términos y condiciones (en adelante, "Términos") tienen como finalidad regular el uso de nuestros servicios de registro manual y la recopilación de datos personales con el fin de llevar a cabo actividades de email marketing y estudios de mercado internos.</p>
      <p>1.2. Aceptación de los Términos: Al utilizar nuestros servicios, usted acepta y se compromete a cumplir con estos Términos en su totalidad. Si no está de acuerdo con alguno de los términos aquí establecidos, le recomendamos que no utilice nuestros servicios.</p>
      {/* Recopilación de Datos del Usuario */}
      <h3>Recopilación de Datos del Usuario</h3>
      <p>2.1. Tipos de Datos Recopilados: Durante el proceso de registro, se solicitará al usuario que proporcione información personal, incluyendo, pero sin limitarse a:</p>
      <ul>
        <li>Nombre completo.</li>
        <li>Dirección de correo electrónico.</li>
        <li>Número de teléfono.</li>
        <li>Contraseña.</li>
      </ul>
      <p>2.2. Métodos de Recopilación de Datos: Los datos serán recopilados a través de formularios de registro en línea y podrán ser actualizados por el usuario en cualquier momento.</p>
      {/* Uso de los Datos */}
      <h3>Uso de los Datos</h3>
      <p>3.1. Email Marketing: Los datos recopilados podrán ser utilizados para enviar comunicaciones de marketing por correo electrónico, incluyendo newsletters, promociones y actualizaciones relevantes.</p>
      <p>3.2. Estudios de Mercado Internos: Los datos podrán ser utilizados para realizar análisis y estudios de mercado internos con el objetivo de mejorar nuestros productos y servicios.</p>
      {/* Protección de Datos y Privacidad */}
      <h3>Protección de Datos y Privacidad</h3>
      <p>4.1. Compromiso con la Privacidad del Usuario: Nos comprometemos a proteger la privacidad de los datos personales proporcionados por los usuarios y a cumplir con todas las leyes de privacidad de datos vigentes en Ecuador.</p>
      <p>4.2. Medidas de Protección de Datos: Implementamos medidas de seguridad adecuadas para proteger la integridad y confidencialidad de los datos personales. Sin embargo, no podemos garantizar la seguridad absoluta de la información transmitida por Internet.</p>
      {/* Derechos del Usuario */}
      <h3>Derechos del Usuario</h3>
      <p>5.1. Acceso a los Datos: Los usuarios tienen derecho a acceder a sus datos personales en cualquier momento y a solicitar información sobre su uso.</p>
      <p>5.2. Corrección y Eliminación de Datos: Los usuarios pueden solicitar la corrección o eliminación de sus datos personales de nuestra base de datos en cualquier momento.</p>
      {/* Cookies y Tecnologías de Seguimiento */}
      <h3>Cookies y Tecnologías de Seguimiento</h3>
      <p>6.1. Uso de Cookies: Utilizamos cookies y tecnologías de seguimiento para mejorar la experiencia del usuario. Los usuarios pueden configurar sus preferencias de cookies en cualquier momento.</p>
      <p>6.2. Control de Seguimiento: Proporcionamos opciones para que los usuarios controlen las cookies y otras tecnologías de seguimiento utilizadas en nuestro sitio web.</p>
      {/* Enlaces y Servicios de Terceros */}
      <h3>Enlaces y Servicios de Terceros</h3>
      <p>7.1. Interacción con Servicios de Terceros: Nuestro sitio web puede contener enlaces o integraciones con servicios de terceros. No somos responsables de las políticas de privacidad o prácticas de estos terceros.</p>
      <p>7.2. Descargo de Responsabilidad para Enlaces de Terceros: Los usuarios deben ejercer precaución al interactuar con enlaces de terceros y deben revisar las políticas de privacidad de los sitios web externos.</p>
      {/* Responsabilidades del Usuario */}
      <h3>Responsabilidades del Usuario</h3>
      <p>8.1. Proporcionar Información Exacta: Los usuarios se comprometen a proporcionar información precisa y actualizada durante el proceso de registro y a mantener sus datos personales actualizados.</p>
      <p>8.2. Prohibiciones de Uso Incorrecto: Los usuarios se comprometen a no hacer un uso indebido de nuestros servicios, incluyendo el envío de contenido ofensivo, ilegal o no autorizado.</p>
      {/* Modificaciones a los Términos */}
      <h3>Modificaciones a los Términos</h3>
      <p>9.1. Notificación de Cambios: Nos reservamos el derecho de modificar estos Términos en cualquier momento. Los cambios se comunicarán a los usuarios a través de nuestro sitio web o por correo electrónico.</p>
      <p>9.2. Aceptación de Cambios: El uso continuado de nuestros servicios después de cualquier modificación de estos Términos implicará la aceptación de los cambios por parte del usuario.</p>
      {/* Ley Aplicable y Jurisdicción */}
      <h3>Ley Aplicable y Jurisdicción</h3>
      <p>10.1. Leyes Aplicables: Estos Términos se rigen por las leyes de Ecuador.</p>
      <p>10.2. Jurisdicción Competente: Cualquier disputa relacionada con estos Términos estará sujeta a la jurisdicción de los tribunales de Ecuador.</p>
      {/* Información de Contacto */}
      <h3>Información de Contacto</h3>
      <p>11.1. Cómo Contactarnos: Los usuarios pueden ponerse en contacto con nosotros en cualquier momento para plantear preguntas o preocupaciones relacionadas con la privacidad de datos.</p>
      {/* Anexos */}
      <h3>Anexos</h3>
      <p>12.1. Leyes y Regulaciones de Ecuador: Se adjuntan anexos con las leyes y regulaciones de protección de datos aplicables en Ecuador.</p>
      <p>12.2. Política de Privacidad Detallada: Consulte nuestra Política de Privacidad para obtener información más detallada sobre cómo manejamos los datos personales.</p>
      {/* Firma Digital */}
      <h3>Firma Digital</h3>
      <p>Para completar su registro, debe firmar digitalmente estos Términos y Condiciones. Utilice el área de firma a continuación para hacerlo.</p>
      <div style={{ border: '1px solid black', padding: '10px', marginTop: '10px' }}>
        <SignatureCanvas
          ref={signatureRef}
          penColor='black'
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
        />
        <p>Firme dentro de este cuadro</p>
      </div>
      <button onClick={handleAcceptTerms}>Aceptar y firmar</button>
      <button onClick={() => setIsTermsModalOpen(false)}>Cerrar</button>
    </div>
  );

  const pdfUrl = '/path/to/scanenencuestasxy.pdf'; // Ajusta la ruta al archivo PDF
  const whatsappMessage = `¡Regístrate, amigo!`;
  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}&attachment=${encodeURIComponent(window.location.origin + pdfUrl)}`;

  return (
    <div className="auth-container">
      <div className="header">
        <h2>Registro en Encuestadora XY</h2>
      </div>
      <form onSubmit={handleRegister}>
        <input type="text" placeholder="Nombre Completo" value={name} onChange={(e) => setName(e.target.value)} required />
        <input type="email" placeholder="Correo Electrónico" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input type="tel" placeholder="Número de Teléfono" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
        <input type="password" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <input type="text" placeholder="Cédula de identidad" value={identificationId} onChange={(e) => setIdentificationId(e.target.value)} required />
        <button type="button" onClick={openTermsModal}>Leer Términos y Condiciones</button>
        <button type="submit" disabled={isRegistering}>Registrarse</button>
      </form>
      {registerStatus && <p>{registerStatus}</p>}
      <button
        className="whatsapp-share-button"
        onClick={() => window.open(whatsappUrl, '_blank')}
      >
        <img src={whatsappIcon} alt="WhatsApp" style={{ maxWidth: '30px', marginRight: '10px' }} />
        Compartir con un amigo
      </button>
      <Modal isOpen={isTermsModalOpen} onRequestClose={() => setIsTermsModalOpen(false)} contentLabel="Términos y Condiciones">
        {termsContent}
      </Modal>
      <Modal isOpen={isRegistering} contentLabel="Proceso de Registro">
        <div style={{ textAlign: 'center' }}>
          <h2>Estamos registrándote 🔄</h2>
          <p>Por favor, espera un momento... 😊</p>
          <p>🚀🚀🚀</p>
        </div>
      </Modal>
      <Modal isOpen={isRegistrationSuccessful} contentLabel="Registro Exitoso">
        <div className="success-modal-content">
          <h2>Registro Exitoso! 😀</h2>
          <h2>Bienvenido a Encuestadora XY</h2>
          <p>🎉 Felicidades, {name}, tu registro se ha completado con éxito.</p>
          <p>Tu código de nuevo miembro es: <strong>{userCode}</strong></p>
          <p>Por favor, presenta este código para confirmar tu registro.</p>
          <button onClick={() => navigate('/login/encuestadora_xy')}>Iniciar sesión</button>
        </div>
      </Modal>
    </div>
  );
};

export default RegisterEncuestadoraXY;
