import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cardMom from '../../assets/momcard.png';
import cardDad from '../../assets/giftcardgeneral.png';
import cardKid from '../../assets/cardmom.png';
import logo from './logo-empanadasdepaco.png'; // Asegúrate de que esta es la ruta correcta al logo
import './GiftCards.css';  // Asegúrate de importar el archivo CSS adecuado con los estilos personalizados




const GiftCards = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [dedication, setDedication] = useState('');
  const [amount, setAmount] = useState('');

  const handleEditClick = () => {
    setShowEdit(true);
  };

  const handleSaveClick = () => {
    setShowEdit(false);
    // Aquí puedes realizar alguna acción con la dedicatoria y el monto, como guardarlos en el estado o enviarlos al servidor.
  };

  const handleCancelClick = () => {
    setShowEdit(false);
  };

  return (
    <div className="gift-cards-container">
      <Link to="/" className="back-to-menu">Regresar al Menú</Link>
      <h2 className="gift-cards-heading">Gift Cards</h2>
      <div className="gift-card">
        <Link to="/paycrest">
          <img src={cardMom} alt="Tarjeta para mamá" />
        </Link>
        <p className="gift-card-title">Personalización para mamá</p>
        <div className="dedication">
          {showEdit ? (
            <>
              <input
                type="text"
                placeholder="Monto"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="dedication-input"
              />
              <input
                type="text"
                placeholder="Dedicatoria"
                value={dedication}
                onChange={(e) => setDedication(e.target.value)}
                className="dedication-input"
              />
              <button onClick={handleSaveClick} className="save-button">
                Guardar
              </button>
              <button onClick={handleCancelClick} className="cancel-button">
                Cancelar
              </button>
            </>
          ) : (
            <>
              <span className="dedication-text">Monto: {amount}</span>
              <span className="dedication-text">Dedicatoria: {dedication}</span>
              <button onClick={handleEditClick} className="edit-button">
                Editar
              </button>
            </>
          )}
        </div>
        <Link to="/paycrest" className="gift-card-link">
          Comprar
        </Link>
      </div>
      <div className="gift-card">
        <Link to="/paycrest">
          <img src={cardDad} alt="Tarjeta para papá" />
        </Link>
        <p className="gift-card-title">Personalización para papá</p>
        <div className="dedication">
          {showEdit ? (
            <>
              <input
                type="text"
                placeholder="Monto"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="dedication-input"
              />
              <input
                type="text"
                placeholder="Dedicatoria"
                value={dedication}
                onChange={(e) => setDedication(e.target.value)}
                className="dedication-input"
              />
              <button onClick={handleSaveClick} className="save-button">
                Guardar
              </button>
              <button onClick={handleCancelClick} className="cancel-button">
                Cancelar
              </button>
            </>
          ) : (
            <>
              <span className="dedication-text">Monto: {amount}</span>
              <span className="dedication-text">Dedicatoria: {dedication}</span>
              <button onClick={handleEditClick} className="edit-button">
                Editar
              </button>
            </>
          )}
        </div>
        <Link to="/paycrest" className="gift-card-link">
          Comprar
        </Link>
      </div>
      <div className="gift-card">
        <Link to="/paycrest">
          <img src={cardKid} alt="Tarjeta para niño" />
        </Link>
        <p className="gift-card-title">Personalización para niño</p>
        <div className="dedication">
          {showEdit ? (
            <>
              <input
                type="text"
                placeholder="Monto"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="dedication-input"
              />
              <input
                type="text"
                placeholder="Dedicatoria"
                value={dedication}
                onChange={(e) => setDedication(e.target.value)}
                className="dedication-input"
              />
              <button onClick={handleSaveClick} className="save-button">
                Guardar
              </button>
              <button onClick={handleCancelClick} className="cancel-button">
                Cancelar
              </button>
            </>
          ) : (
            <>
              <span className="dedication-text">Monto: {amount}</span>
              <span className="dedication-text">Dedicatoria: {dedication}</span>
              <button onClick={handleEditClick} className="edit-button">
                Editar
              </button>
            </>
          )}
        </div>
        <Link to="/paycrest" className="gift-card-link">
          Comprar
        </Link>
      </div>
    </div>
  );
};

export default GiftCards;
