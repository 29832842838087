import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import whatsappIcon from './whatsapp-icon.png'; // Verifica la ruta al icono de WhatsApp
import './LoginEncuestadoraXY.css';

const LoginEncuestadoraXY = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);
  const { client } = useParams();

  const handleLogin = async (e) => {
    e.preventDefault();
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

    try {
      const response = await fetch(`${backendUrl}/login_encuestadoraxy`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, client })
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data.user);
        navigate('/');
      } else {
        setLoginError(data.message || `Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setLoginError(`Error: ${error.message}`);
    }
  };

  const handleForgotPassword = () => {
    navigate(`/forgot-password/${client}`);
  };

  const pdfUrl = 'https://siemprecondescuentos.com/login/encuestadora_xy';
  const whatsappMessage = `¡Regístrate, amigo!  aquí: ${pdfUrl}`;
  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;

  return (
    <form className="auth" onSubmit={handleLogin}>
      <h2>Bienvenido a Encuestadora XY</h2>
      <input type="email" name="email" placeholder="Tu correo" value={email} onChange={e => setEmail(e.target.value)} required />
      <input type="password" name="password" placeholder="Contraseña" value={password} onChange={e => setPassword(e.target.value)} required />
      <button type="submit">Iniciar sesión</button>
      {loginError && <p>{loginError}</p>}
      <button type="button" onClick={handleForgotPassword}>Olvidé la contraseña</button>
      <button type="button" onClick={() => navigate(`/register-encuestadora_xy`)}>Registrarme como nuevo socio</button>
      <button
        className="whatsapp-share-button"
        onClick={() => window.open(whatsappUrl, '_blank')}
      >
        <img src={whatsappIcon} alt="WhatsApp" style={{ maxWidth: '30px', marginRight: '10px' }} />
        Compartir con un amigo
      </button>
    </form>
  );
};

export default LoginEncuestadoraXY;
