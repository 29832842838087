import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Bar, Pie, Line } from 'react-chartjs-2';
import 'chart.js/auto';

const EstadisticasEncuestaXY = () => {
  const { id } = useParams();
  const [estadisticas, setEstadisticas] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  useEffect(() => {
    const fetchEstadisticas = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/encuestasxy/${id}/estadisticas`);
        console.log('Datos recibidos:', response.data); // Log para ver los datos recibidos
        setEstadisticas(response.data);
      } catch (error) {
        console.error('Error al cargar estadísticas:', error);
      }
    };
    fetchEstadisticas();
  }, [id]);

  const generarDatosGrafico = (pregunta) => {
    const etiquetas = pregunta.opciones.map(opcion => opcion.opcion);
    const datos = pregunta.opciones.map(opcion => opcion.count);

    return {
      labels: etiquetas,
      datasets: [
        {
          label: 'Conteo',
          data: datos,
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(199, 199, 199, 0.6)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(199, 199, 199, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  if (!estadisticas.length) return <p>Cargando estadísticas...</p>;



  const backToMenuStyle = {
    display: 'inline-block',
    margin: '10px 0',
    padding: '5px 10px',
    backgroundColor: '#f0f0f0',
    color: '#333',
    textDecoration: 'none',
    borderRadius: '5px',
    fontWeight: 'bold',
  };

  return (
    <div>
      <Link to="/EncuestasEncuestadoraXY" style={backToMenuStyle}>Regresar al Menú</Link>
      <h2>Estadísticas de la Encuesta</h2>
      {estadisticas.map((pregunta, index) => (
        <div key={index} style={{ marginBottom: '40px' }}>
          <h3>{pregunta.texto}</h3>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Respuesta</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Conteo</th>
              </tr>
            </thead>
            <tbody>
              {pregunta.opciones.map((opcion, idx) => (
                <tr key={idx}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{opcion.opcion}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{opcion.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ marginTop: '20px' }}>
            <h4>Gráfico de Barras</h4>
            <div style={{ width: '100%', height: '400px' }}>
              <Bar data={generarDatosGrafico(pregunta)} options={{ maintainAspectRatio: false }} />
            </div>
            <h4>Gráfico de Pastel</h4>
            <div style={{ width: '100%', height: '400px' }}>
              <Pie data={generarDatosGrafico(pregunta)} options={{ maintainAspectRatio: false }} />
            </div>
            <h4>Gráfico de Línea</h4>
            <div style={{ width: '100%', height: '400px' }}>
              <Line data={generarDatosGrafico(pregunta)} options={{ maintainAspectRatio: false }} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EstadisticasEncuestaXY;
