import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DownloadLatestQRMenu = () => {
  const [qrMenuUrl, setQrMenuUrl] = useState('');
  const [loading, setLoading] = useState(false);
  
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  useEffect(() => {
    setLoading(true);
    axios.get(`${backendUrl}/latest-qr-menu-url`)
      .then(response => {
        setQrMenuUrl(response.data.qrMenuUrl);
      })
      .catch(error => {
        console.error('Error fetching latest QR menu URL:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [backendUrl]);

  const handleDownloadClick = (event) => {
    event.preventDefault(); // Evitar que el evento del clic cause una recarga de la página
    if (qrMenuUrl) {
      window.open(qrMenuUrl);
    }
  };

  return (
    <div>
      {loading ? (
        <p>Cargando el último QR...</p>
      ) : qrMenuUrl ? (
        <div>
          <button onClick={handleDownloadClick}>Descargar Último QR Menú</button>
        </div>
      ) : (
        <p>No se encontró un QR para descargar.</p>
      )}
    </div>
  );
};

export default DownloadLatestQRMenu;
