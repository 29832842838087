import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../auth/AuthContext';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

// Set the root app element for accessibility with modals
Modal.setAppElement('#root');

const RespuestasEncuestaXY = () => {
    const { user, token } = useContext(AuthContext);
    const [encuestas, setEncuestas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showThankYouModal, setShowThankYouModal] = useState(false);
    const [validationCode, setValidationCode] = useState('');
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

    const fetchEncuestas = async () => {
        if (!user?.id) {
            setError('User ID not available. Please log in.');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${backendUrl}/api/encuestasxy`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const activas = response.data.filter(e => e.activa && (!e.respondientes || !e.respondientes.includes(user.id)));
            const encuestasConPreguntas = await Promise.all(activas.map(async encuesta => {
                const resp = await axios.get(`${backendUrl}/api/encuestasxy/${encuesta.id}/preguntas`);
                return { ...encuesta, preguntas: resp.data || [], respuestas: resp.data.map(p => ({ preguntaId: p.id, valor: '' })) };
            }));
            setEncuestas(encuestasConPreguntas);
        } catch (error) {
            console.error('Error al cargar las encuestas:', error);
            setError('No se pudieron cargar las encuestas');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEncuestas();
    }, [user?.id, token]);

    const handleInputChange = (preguntaId, valor) => {
        setEncuestas(encuestas.map(encuesta => ({
            ...encuesta,
            respuestas: encuesta.respuestas.map(respuesta =>
                respuesta.preguntaId === preguntaId ? { ...respuesta, valor } : respuesta
            )
        })));
    };

    const handleCheckboxChange = (preguntaId, opcion) => {
        setEncuestas(encuestas.map(encuesta => ({
            ...encuesta,
            respuestas: encuesta.respuestas.map(respuesta =>
                respuesta.preguntaId === preguntaId
                    ? { ...respuesta, valor: respuesta.valor.includes(opcion) ? respuesta.valor.filter(item => item !== opcion) : [...respuesta.valor, opcion] }
                    : respuesta
            )
        })));
    };

    const handleSubmit = async (encuestaId, respuestas) => {
        if (!user?.id) {
            alert('User ID is missing, please login again.');
            return;
        }

        try {
            const formattedRespuestas = respuestas.map(r => ({
                preguntaId: r.preguntaId,
                respuesta: Array.isArray(r.valor) ? JSON.stringify(r.valor) : r.valor
            }));

            const response = await axios.post(`${backendUrl}/api/encuestasxy/${encuestaId}/respuestas`, {
                usuarioId: user.id,
                respuestas: formattedRespuestas
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.data) {
                setValidationCode(response.data.code); // Set the validation code from the response
                setShowThankYouModal(true);
                fetchEncuestas();
            }
        } catch (error) {
            console.error('Error al enviar respuestas:', error.response ? error.response.data : error);
            alert('Error al enviar respuestas');
        }
    };

    const closeModal = () => {
        setShowThankYouModal(false);
        setValidationCode(''); // Reset validation code when closing the modal
    };

    if (loading) return <p>Cargando encuestas...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <Link to="/" className="logo-link">
                <h2>Encuestadora XY</h2>
            </Link>
            <h1>Encuestas Activas</h1>
            {encuestas.length > 0 ? (
                <ul>
                    {encuestas.map(encuesta => (
                        <li key={encuesta.id}>
                            <h2>{encuesta.titulo}</h2>
                            {encuesta.preguntas.map(pregunta => (
                                <div key={pregunta.id}>
                                    <p>{pregunta.texto}</p>
                                    {pregunta.tipo === 'multiple_choice' ? (
                                        <div>
                                            {pregunta.opciones && pregunta.opciones.map((opcion, index) => (
                                                <div key={index}>
                                                    <input
                                                        type="checkbox"
                                                        id={`opcion${index}`}
                                                        value={opcion}
                                                        onChange={() => handleCheckboxChange(pregunta.id, opcion)}
                                                    />
                                                    <label htmlFor={`opcion${index}`}>{opcion}</label>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <input
                                            type="text"
                                            onChange={e => handleInputChange(pregunta.id, e.target.value)}
                                        />
                                    )}
                                </div>
                            ))}
                            <button onClick={() => handleSubmit(encuesta.id, encuesta.respuestas)}>
                                Enviar Respuestas
                            </button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No hay encuestas disponibles para responder.</p>
            )}

            <Link to="/" className="button">Regresar al Menú</Link>

            <Modal
                isOpen={showThankYouModal}
                onRequestClose={closeModal}
                contentLabel="Thank You Modal"
                className="modal"
            >
                <div className="modal-content">
                    <h2>¡Gracias por responder la encuesta!</h2>
                    <p>Tu opinión es muy importante para nosotros.</p>
                    <p>Tu código de validación es: <strong>{validationCode}</strong></p>
                    <button onClick={closeModal}>Cerrar</button>
                </div>
            </Modal>
        </div>
    );
};

export default RespuestasEncuestaXY;
