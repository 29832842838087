import React, { useState, useEffect } from 'react';
import './RestaurantesYMas.css';
import { Link } from 'react-router-dom';
import logo from './logo-empanadasdepaco.png';

const RestaurantesYMas = () => {
  const [articulos, setArticulos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  useEffect(() => {
    const fetchArticulos = async () => {
      const response = await fetch(`${backendUrl}/restaurantes`);
      const data = await response.json();
      setArticulos(data.articulos);
    };

    fetchArticulos();
  }, [backendUrl]);

  const nextArticle = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === articulos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const previousArticle = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? articulos.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="restaurantes-blog">
      <div className="logo-header">
        <img src={logo} alt="Logo Empanadas del Paco" className="logo" />
      </div>
        <Link to="/" className="home-link">Regresar al menu</Link> {/* Botón para regresar al inicio */}
      {articulos.length > 0 && (
        <div className="blog-articulo">
          <h3>{articulos[currentIndex].titulo}</h3>
          <p>{articulos[currentIndex].contenido}</p>
          <button onClick={previousArticle}>Anterior</button>
          <button onClick={nextArticle}>Siguiente</button>
        </div>
      )}
    </div>
  );
};

export default RestaurantesYMas;
